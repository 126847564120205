import React from 'react'
import Logo from '../images/logotip.svg'
import Api_ from '../service/api'
import {Link, withRouter} from 'react-router-dom'
import Loader from 'react-loader-advanced';
import {Alert} from "reactstrap";

const Api = new Api_();


class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.getUrlPram('email', this.props.location.search) ? this.getUrlPram('email', this.props.location.search) : '',
            loadingInProgress: false,
            errorResponse: false,
            successResponse: false
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const _this = this;

        let inputName = e.target.name.split('.')[0];
        let inputSubName = e.target.name.split('.')[1];
        let inputSubSubName = e.target.name.split('.')[2];
        let inputValue = e.target.value;

        let statusCopy = Object.assign({}, this.state);

        if (inputSubSubName) {
            statusCopy[inputName][inputSubName][inputSubSubName] = inputValue;
        } else if (typeof inputSubSubName === 'undefined' && inputSubName) {
            statusCopy[inputName][inputSubName] = inputValue;
        } else {
            statusCopy[inputName] = inputValue;
        }

        _this.setState(statusCopy);
    }

    getUrlPram(name, url) {
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        let regexS = "[\\?&]" + name + "=([^&#]*)";
        let regex = new RegExp(regexS);
        let results = regex.exec(url);
        return results == null ? null : results[1];
    }


    componentDidMount() {
        document.body.classList.add('signin-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('signin-page');
    }

    onSubmit() {
        const _this = this;

        _this.setState({
            errorResponse: false,
            loadingInProgress: true
        });

        console.log(_this.state.email);

        let data = {
            email: _this.state.email,
        };

        Api.register(data, function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            _this.setState({loadingInProgress: false});

            if (dataRetrieved) {
                console.log(responseData);
                //_this.props.history.push('/');
                _this.setState({successResponse: true});
            } else {
                _this.setState({errorResponse: true});
                console.log("Something went wrong");
            }

        });


        //this.props.history.push('/');
    }

    render() {
        const _this = this;
        const spinner = <i className="text-black-50 fa fa-2x fa-circle-o-notch fa-spin fa-fw"></i>;

        return (
            <div className="signin-page">
                <div className="wrapper">

                    <div className="header">
                        <img src={Logo} className="logo img-responsive"/>
                        <div className="portalName badge badge-primary">PROSTOVOLJSKI PORTAL</div>
                    </div>


                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 offset-md-3 col-sm-12 col-xs-12">


                                <Loader
                                    className="pt-2 pb-2"
                                    show={_this.state.loadingInProgress}
                                    message={spinner}
                                    contentBlur={0}
                                    backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.6)'}}>

                                    <form autoComplete="off">

                                        {_this.state.errorResponse &&
                                        <div className="row">
                                            <div className="col-sm-12 mb-2">
                                                <Alert color="danger">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <i className="pt-2 fa fa-exclamation-triangle fa-2x"></i>
                                                        </div>
                                                        <div className="col-10 text-left">
                                                            Pri registraciji je prišlo do napake. Preverite ali ste
                                                            vpisali pravilne podatke in poskusite znova. <br/><br/>
                                                            <small>V primeru težav se obrnite na <a
                                                                href="mailto:info@sopotniki.org">info@sopotniki.org</a>
                                                            </small>
                                                            .
                                                        </div>
                                                    </div>
                                                </Alert>
                                            </div>
                                        </div>
                                        }

                                        {_this.state.successResponse &&
                                        <div className="row">
                                            <div className="col-sm-12 mb-2">
                                                <Alert color="success">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <i className="pt-2 fa fa-check-circle-o fa-2x"></i>
                                                        </div>
                                                        <div className="col-10 text-left">
                                                            Uspešno ste se oddali zahtevek za registracijo.<br/><br/>
                                                            Prosim, odprite svoj e-poštni nabiralnik in sledite
                                                            navodilom v prejetem sporočilu.
                                                        </div>
                                                    </div>
                                                </Alert>
                                            </div>
                                        </div>
                                        }

                                        {!_this.state.successResponse &&
                                        <div>
                                            <div className="mb-4 text-left"><h5>Registracija</h5></div>

                                            <div className="alert alert-info">
                                                Vpiši e-poštni naslov, ki si ga predhodno sporočil koordinatorju
                                                enote.
                                            </div>
                                            <div className="form-field">
                                                <input autoComplete="off" type="email" placeholder="e-mail" id="email" name="email"
                                                       onChange={_this.onChange} value={_this.state.email}/>
                                            </div>

                                            <div className="form-action">
                                                <input type="submit" className="btn-shadow btn-shadow-primary"
                                                       value="Registracija" onClick={(e) => {
                                                    e.preventDefault();
                                                    _this.onSubmit()
                                                }}/>
                                            </div>

                                            <div className="form-bottom">
                                                <small>
                                                    <Link to="/prijava">Si že registriran?</Link>&nbsp;&nbsp;|&nbsp;
                                                    <Link
                                                        to="/pozabljeno-geslo">Si pozabil geslo?</Link>
                                                </small>
                                            </div>
                                        </div>
                                        }
                                    </form>

                                </Loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(Register)
