import React from 'react'
import {Switch, Route, Redirect, BrowserRouter as Router} from 'react-router-dom'
import Home from './pages/Home'
import FBposts from './pages/FBposts'
import NewPost from './pages/NewPost'
import Register from './pages/Register'
import Login from './pages/Login'
import RegistrationConfirmation from './pages/RegistrationConfirmation'
import PasswordForgotten from './pages/PasswordForgotten'
import Instructions from './pages/Instructions'
import Calendar from './pages/Calendar'
import Error from './pages/Error'
import DailyReportOverview from "./pages/DailyReportOverview";

/* TODO - set isAuthenticated */
let isAuthenticated = true;

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        isAuthenticated === true
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/prijava'
            }}/>
    )}/>
)

const routes = () => (
    <main>

        <Route exact path='/' component={Login}/>
        <PrivateRoute exact path='/objave' component={Home}/>
        <PrivateRoute exact path='/objave/urejanje/:postId?' component={NewPost}/>
        <PrivateRoute exact path='/fb-objave' component={FBposts}/>
        <Route path='/prijava' component={Login}/>
        <Route path='/registracija' component={Register}/>
        <Route path='/potrditev-registracije' component={RegistrationConfirmation}/>
        <Route path='/pozabljeno-geslo' component={PasswordForgotten}/>
        <Route path='/dokumenti' component={Instructions}/>
        <Route path='/koledar' component={Calendar}/>
        <Route path='/dnevni-nalog' component={DailyReportOverview}/>
        <Route path='/napaka' component={Error}/>


    </main>
)

export default routes
