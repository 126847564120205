import React from 'react'
import Logo from '../images/logotip.svg'
import Api_ from '../service/api'
import { Link } from 'react-router-dom'

const Api = new Api_();


class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: ''
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.id]: e.target.value});
    }


    componentDidMount() {
        document.body.classList.add('signin-page');
    }

    componentWillUnmount(){
        document.body.classList.remove('signin-page');
    }

    onSubmit() {
        // todo: ajax post

        let data = {
            email: this.state.email,
            password: this.state.password
        };

        Api.login(data, function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            if (dataRetrieved) {
                console.log(responseData);
                this.props.history.push('/'); // TODO: check if authenticated on router
            } else {
                console.log("Something went wrong");
            }

        });



        //this.props.history.push('/');
    }

    render() {
        const _this = this;

        return(
        <div className="signin-page">
            <div className="wrapper">

                <div className="header">
                    <img src={Logo} className="logo img-responsive"/>
                    <div className="portalName badge badge-primary">PROSTOVOLJSKI PORTAL</div>
                </div>

                <form>

                    <div className="mb-4 text-left"><h5>Pozabljeno geslo</h5></div>

                    <div className="alert alert-info">
                        Vpiši e-poštni naslov, kot si ga predhodno sporočil koordinatorju enote.
                    </div>

                    <div className="form-field">
                        <input type="email" placeholder="e-mail" id="email" onChange={_this.state.onChange}/>
                    </div>

                    <div className="form-action">
                        <input type="submit" className="btn-shadow btn-shadow-primary" value="Ponastavi geslo" onClick={(e) => { _this.onSubmit() }} />
                    </div>

                </form>
            </div>
        </div>
        )
    }

}

export default Register
