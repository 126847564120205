import config_ from '../config.json';
import axios from 'axios'
import Cookies from 'universal-cookie';
import moment from 'moment';

const config = config_;
const cookies = new Cookies();


let commonHeadersSet = false;


class Api {

    setCommonHeaders() {

        console.log("axios.defaults.headers.common ", axios.defaults.headers.common);

        if(commonHeadersSet && axios.defaults.headers.common['unit_id']) {
            return
        }

        let token = null;
        if(cookies.get('sopotniki_volunteer') && cookies.get('sopotniki_volunteer').token) {
            token = cookies.get('sopotniki_volunteer').token;
        }

        axios.defaults.headers.common['authorization'] = token;
        axios.defaults.headers.common['portal'] = 'PP';


        if(sessionStorage.getItem('sopotniki_selected_unit_id')) {
            axios.defaults.headers.common['unit_id'] = sessionStorage.getItem('sopotniki_selected_unit_id');
        }

        commonHeadersSet = true;

        console.log("token ", token);
        console.log("-> axios.defaults.headers.common ", axios.defaults.headers.common);
    }


    handleErrors(callback, error) {

        console.log("&&&&&&&&&&");
        console.log(error);
        console.log(error.response);
        console.log("&&&&&&&&&&");

        if (typeof error.response != 'undefined') {

            if (error.response.status === 400) {
                callback(false, error.response);
            } else if (error.response.status === 401) {
                window.location.replace("#/prijava");
            } else if (error.response.status === 500) {
                console.log("Error 500");

                window.location.replace("#/napaka");
            }
        } else {
            console.log("unknown error");
        }

    }


    login(data, callback) {
        let _this = this;
        axios.post(config.apiRoot + config.actions.login, data)
            .then(function (response) {
                console.log(response.status);

                if (response.status === 200 && !response.data.error) {
                    axios.defaults.headers.common['authorization'] = response.data.data.token;
                    callback(true, response.data);
                } else {
                    callback(false, response.data);
                }

            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            })
    }

    me(data, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.get(config.apiRoot + config.actions.me)
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response.data);
                } else {
                    callback(false, response.data);
                    _this.handleErrors(callback, response.data);
                }
            })
            .catch(function (error) {
                callback(false, error);
                _this.handleErrors(callback, error);
            });
    }

    checkKey(data, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.post(config.apiRoot + config.actions.checkKey, data)
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response.data);
                } else {
                    _this.handleErrors(null, response.data);
                    callback(false, response.data);
                }
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
                callback(false, error);
            });
    }

    registrationConfirmation(data, callback) {
        let _this = this;
        axios.post(config.apiRoot + config.actions.registrationConfirmation, data)
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response.data);
                }
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            });
    }

    register(data, callback) {
        let _this = this;
        axios.post(config.apiRoot + config.actions.register, data)
            .then(function (response) {
                callback((response.status === 200) ? true : false, response);
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
                callback(false, error);
            })
    }

    getPosts(data, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.get(config.apiRoot + config.actions.posts + '/' + data)
            .then(function (response) {
                callback((response.status === 200) ? true : false, response);
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            })
    }

    getPost(postId, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.get(config.apiRoot + config.actions.post + '/' + postId)
            .then(function (response) {
                callback((response.status === 200) ? true : false, response);
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            })
    }

    deletePostAttachment(attachment_id, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.get(config.apiRoot + config.actions.removePostAttachment + '/' + attachment_id)
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response.data);
                }
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            });
    }

    submitPost(data, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.post(config.apiRoot + config.actions.submitPost, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                console.log(response.status);

                if (response.status === 200 && !response.data.error) {
                    callback(true, response.data);
                }

            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            })
    }

    getEvents(data, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.get(config.apiRoot + config.actions.getEvents + '/' + data.unit_id + '/' + data.year + '/' + data.month)
            .then(function (response) {
                callback((response.status === 200) ? true : false, response.data);
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            })
    }

    getDailyReport(data, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.get(config.apiRoot + config.actions.getDailyRepor + '/' + data.id)
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response.data);
                }
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            });
    }

    setDailyReport(data, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.post(config.apiRoot + config.actions.setDailyReport, data)
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response.data);
                }
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            });
    }

    getComments(data, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.get(config.apiRoot + config.actions.comments + '/' + data.post_id + '/' + data.unit_id)
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response.data);
                }
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            });
    }

    submitComment(data, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.post(config.apiRoot + config.actions.submitComment, data)
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response.data);
                }
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            });
    }

    deleteComment(data, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.post(config.apiRoot + config.actions.deleteComment, data)
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response.data);
                }
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            });
    }

    deletePost(id, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.delete(config.apiRoot + config.actions.post + '/' + id)
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response.data);
                }
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            });
    }

    getStatistics(callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.get(config.apiRoot + config.actions.statistics)
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response.data);
                }
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            });
    }

    getVolunteers(unit_id, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.get(config.apiRoot + config.actions.volunteers + '/' + unit_id)
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response.data);
                }
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            });
    }

    getFile(googleDriveLink, callback) {
        let _this = this;
        _this.setCommonHeaders();

        axios.get(config.apiRoot + config.actions.getFile + '/' + encodeURI(googleDriveLink))
            .then(function (response) {
                if (response.status === 200) {
                    callback(true, response);
                }
            })
            .catch(function (error) {
                _this.handleErrors(callback, error);
            });
    }

}

export default Api
