import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import bgImg from '../images/unsplash/aranxa-esteve-130749.jpg'
import avatar from '../images/uifaces/10.jpg'
import PostItem from '../components/PostItem'
import {Link, withRouter} from "react-router-dom";
import Api_ from "../service/api";
import Cookies from "universal-cookie";
import Loader from 'react-loader-advanced';
import PubSub from "pubsub-js";
import StatisticsOverlay from "../components/StatisticsOverlay";
import ReactGA from 'react-ga';
import _ from "lodash";
import $ from 'jquery';

const Api = new Api_();
const cookies = new Cookies();

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            renderedPosts: [],
            loadingInProgress: false,
            showStatisticsOverlay: false,
            pagination: {
                page: 1,
                pageCount: 1,
                pageSize: 10,
                rowCount: 25,
            }
        };

        this.getPostsData = this.getPostsData.bind(this);
        this.renderPaginationPages = this.renderPaginationPages.bind(this);
    }

    componentWillMount() {
        const _this = this;

        if (!cookies.get('sopotniki_volunteer')) {
            _this.props.history.push('/prijava');
        }
    }

    componentDidMount() {
        const _this = this;

        ReactGA.pageview("/objave");

        _this.setState({
            loadingInProgress: true
        });

        setTimeout(function () {
            _this.getPostsData();
        },100);

    }

    getPostsData() {
        const _this = this;

        window.scrollTo(0, 0);

        _this.setState({
            loadingInProgress: true,
            posts: [],
            renderedPosts: []
        });

        Api.getPosts((_this.state.pagination.page ? _this.state.pagination.page : 1), function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            if (dataRetrieved) {

                _this.setState({
                    posts: responseData.data.data,
                    pagination: responseData.data.pagination,
                });

                _this.renderPosts();


            } else {
                console.log("Something went wrong");
            }

        });

    }

    renderPaginationPages() {
        const _this = this;

        let paginationPages = [];

        _.each(new Array(_this.state.pagination.pageCount), function (val, i) {

            paginationPages.push(
                <li key={i} className={'page-item' + (_this.state.pagination.page === (i+1) ? ' active' : '')}>
                    <a className="page-link" onClick={(e) => {
                        if(_this.state.pagination.page != i+1) {

                            _this.state.pagination.page = i+1;
                            _this.forceUpdate();
                            _this.getPostsData();
                        }
                    }}>{(i + 1)}</a>
                </li>
            );
        });


        console.log("paginationPages ", paginationPages);
        return paginationPages;

    }

    renderPosts() {
        const _this = this;

        let postsContent = [];

        console.log("_this.state.posts", _this.state.posts);

        if (_this.state.posts.length === 0) {
            postsContent.push(<div className="mt-5"><i>Ni objav!</i></div>);
        }

        for (let k = 0; k < _this.state.posts.length; k++) {
            let postItem = _this.state.posts[k];
            console.log(k);

            postsContent.push(<PostItem key={k} postData={postItem} getPostsData={_this.getPostsData}/>);
        }
        ;

        _this.setState({
            renderedPosts: postsContent,
            loadingInProgress: false
        });
    }

    componentWillUnmount() {

    }

    render() {
        const _this = this;
        const spinner = <i className="text-black-50 fa fa-2x fa-circle-o-notch fa-spin fa-fw"></i>;

        return (

            <div>

                <Header/>


                <div className="index-app-hero">
                    <div className="container">
                        <h2 className="customFadeInUp">
                            <blockquote>Ne moremo pomagati vsem, <br/>a vsak lahko pomaga nekomu. <br/>
                                <small style={{fontSize: '14px', fontWeight: '100'}}>Ronald Reagan</small>
                            </blockquote>
                        </h2>

                        <Link to='/koledar' className="btn-pill btn-pill-info btn-pill-lg customFadeInUp">Vpiši se
                            v e-koledar</Link>

                        <Link to='/objave/urejanje'
                              className="btn-pill btn-pill-success ml-md-3 ml-lg-3 ml-xl-3 ml-sm-0 btn-pill-lg customFadeInUp">Napiši
                            novo objavo</Link>


                        <a onClick={(e) => {
                            _this.setState({showStatisticsOverlay: true});
                            _this._statisticsOverlayComponent.overlayDisplayed();
                        }}
                           className="btn-pill btn-pill-primary text-white btn-pill-lg ml-md-3 ml-lg-3 ml-xl-3 ml-sm-0 customFadeInUp">Sopotniki
                            v 7 dneh</a>
                    </div>
                </div>

                <Loader
                    className="pt-2 pb-2"
                    show={_this.state.loadingInProgress}
                    message={spinner}
                    contentBlur={0}
                    backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.6)'}}>

                    <div className="blog-posts-list" style={{'minHeight': '500px'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    {_this.state.renderedPosts}
                                </div>
                            </div>
                        </div>
                    </div>

                </Loader>


                {(_this.state.pagination && _this.state.pagination.pageCount > 1) &&
                <div className="container">
                    <nav className="blog-pager mt-2">

                        <ul className="pagination justify-content-center">

                            <li className="page-item">
                                <a className={'page-link' + ((_this.state.pagination.page - 1) > 1 ? '' : ' disabled')}
                                   onClick={(e) => {
                                       if (_this.state.pagination.page - 1 > 1) {
                                           _this.state.pagination.page -= 1;
                                           _this.forceUpdate();
                                           _this.getPostsData();
                                       }
                                   }}>&larr;</a>
                            </li>
                            {_this.renderPaginationPages()}
                            <li className="page-item">
                                <a className={'page-link' + (_this.state.pagination.page >= _this.state.pagination.pageCount  ? ' disabled' : ' ')}
                                   onClick={(e) => {
                                       if (_this.state.pagination.page < _this.state.pagination.pageCount) {
                                           _this.state.pagination.page += 1;
                                           _this.forceUpdate();
                                           _this.getPostsData();
                                       }
                                   }}>&rarr;</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                }

                <div
                    className={_this.state.showStatisticsOverlay ? "statisticsOverlayContainer in" : "statisticsOverlayContainer"}>
                    <a className="closeBtn" onClick={(e) => {
                        _this.setState({showStatisticsOverlay: false});
                        _this._statisticsOverlayComponent.overlayClosed();
                    }}> <i className="fa fa-3x fa-times-circle"></i></a>
                    <StatisticsOverlay ref={(statisticsOverlayComponent) => {
                        _this._statisticsOverlayComponent = statisticsOverlayComponent;
                    }}/>
                </div>


                <Footer/>


            </div>
        )
    }
}


export default withRouter(Home)
