import React from 'react'
import moment from 'moment';
import Header from './Header'
import Footer from './Footer'
import bgImg from '../images/unsplash/aranxa-esteve-130749.jpg'
import _ from 'lodash';
import avatar from '../images/uifaces/10.jpg'
import Attachment from "./Attachment";
import Cookies from "universal-cookie";
import Comments from "./Comments";
import Lightbox from 'lightbox-react';
import {Link} from "react-router-dom";
import {Button, ButtonGroup} from 'reactstrap';
import PubSub from "pubsub-js";
import config from './../config'
import Loader from "react-loader-advanced";
import {withSwalInstance} from 'sweetalert2-react';
import swal from 'sweetalert2';
import Api_ from "../service/api";
import ReactGA from 'react-ga';
import $ from "jquery";
import visible from "jquery-visible"

const cookies = new Cookies();
const Api = new Api_();
const SweetAlert = withSwalInstance(swal);

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class PostItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postId: this.props.postData ? this.props.postData.id : null,
            postUserId: this.props.postData ? this.props.postData.user_id : null,
            title: this.props.postData ? decodeURI(this.props.postData.title) : '',
            content: this.props.postData ? this.props.postData.content : '',
            createdAtDate: this.props.postData ? moment(this.props.postData.date).format('DD. MM. YYYY') : '',
            createdAtTime: this.props.postData ? moment(this.props.postData.date).format('HH:mm') : '',
            userId: this.props.postData ? this.props.postData.user_id : '',
            userName: this.props.postData ? this.props.postData.user.first_name + ' ' + this.props.postData.user.last_name : '',
            userImage: this.props.postData ? (this.props.postData.user.profile_image ? config.apiRoot + config.actions.getFile + btoa(encodeURI("https://drive.google.com/thumbnail?authuser=0&sz=w40&id=" + this.props.postData.user.profile_image)) : this.props.postData.user.internal_app_image_url) : 'https://placehold.it/200?text=X',
            attachments: this.props.postData ? this.props.postData.attachments : null,
            preloaderImageLoaded: false,
            postPreloadImg: null,
            postImg: null,
            renderedAttachments: [],
            loadingInProgress: false,
            commentsData: [],
            renderedComments: null,
            showConfirmationDialog: false
        };

        console.log(this.props);

        this.getCommentsData = this.getCommentsData.bind(this);
        this.commentsUpdateHandler = this.commentsUpdateHandler.bind(this);
        this.onConfirmedDelete = this.onConfirmedDelete.bind(this);
        this.trackPostReadEvent = this.trackPostReadEvent.bind(this);
    }



    trackPostReadEvent(event) {
        const _this = this;

        let timer = null;


        if($('#post_content_' + _this.state.postId).visible( false )) {

            timer = window.setTimeout(function () {

               console.log($('#post_content_' + _this.state.postId));
               console.log('post_content_' + _this.state.postId + ' -> ' + $('#post_content_' + _this.state.postId).visible( false ));

               let volunteerName = "[unknown]" ;

               if(sessionStorage.getItem('sopotniki_me')) {
                  volunteerName = JSON.parse(sessionStorage.getItem('sopotniki_me')).volunteers.first_name + ' ' + JSON.parse(sessionStorage.getItem('sopotniki_me')).volunteers.last_name;
               }

               ReactGA.event({
                   category: 'Post',
                   action: '[post read] ' + _this.state.title,
                   label: volunteerName,
                   value: 1
               });

               window.clearTimeout(timer);

           }, 2000);

        } else {
            if(timer) {
                window.clearTimeout(timer);
            }

        }
    }

    commentsUpdateHandler() {
        const _this = this;

        _this.getCommentsData();
    }



    getCommentsData() {
        const _this = this;


        console.log("!!!!!!!!!!!!!!! ------> getCommentsData");

        _this.setState({
            commentsData: [],
            renderedComments: null
        });

        let data = {
            post_id: _this.state.postId,
            unit_id: sessionStorage.getItem('sopotniki_selected_unit_id'),
        };


        Api.getComments(data, function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            if (dataRetrieved) {
                console.log(responseData);


                _this.setState({
                    commentsData: responseData.data,
                    renderedComments: (
                        <Comments key={_this.state.postId} commentsData={responseData.data} postId={_this.state.postId}
                                  getCommentsData={_this.getCommentsData}></Comments>)
                });


            } else {
                console.log("Something went wrong" + responseData);
            }
        });
    }

    componentDidMount() {
        const _this = this;

        _this.setState({
            loadingInProgress: true,
        });

        _this.renderAttachments();
        _this.getCommentsData();

        window.addEventListener('scroll', _.debounce((event) => { _this.trackPostReadEvent(event) }, 500));
    }

    renderAttachments() {
        const _this = this;

        if (!_this.state.attachments) return

        let attachments = [];

        _.each(_this.state.attachments, function (value, idx, arr) {
            //console.log(value);
            if ((value.mime_type === 'image/jpeg' || value.mime_type === 'image/png' || value.mime_type === 'image/gif') && !_this.state.postImg) {
                _this.setState({
                    postPreloadImg: config.apiRoot + config.actions.getFile + btoa(encodeURI('https://drive.google.com/thumbnail?authuser=0&sz=w30&id=' + value.file)),
                    postImg: config.apiRoot + config.actions.getFile + btoa(encodeURI('https://drive.google.com/thumbnail?authuser=0&sz=w690&id=' + value.file)),
                    file: value.file
                });
            }
            attachments.push(<Attachment key={idx} dataKey={idx} attachmentData={value}
                                         postId={_this.state.postId}></Attachment>)
        });

        console.log("attachments", attachments);

        _this.setState({
            renderedAttachments: attachments
        });
    }

    onConfirmedDelete() {
        const _this = this;

        Api.deletePost(_this.state.postId, function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            if (dataRetrieved) {

                setTimeout(function () {
                    _this.props.getPostsData();
                }, 100)

            } else {
                console.log("Something went wrong");
            }

        });
    }

    componentWillUnmount() {
    }

    render() {
        const _this = this;
        const spinner = <i className="text-black-50 fa fa-2x fa-circle-o-notch fa-spin fa-fw"></i>;

        return (

            <div id={'post_' + _this.state.postId}>


                <div className="post">

                        <div id={'post_content_' + _this.state.postId}>
                            <div className="title">
                                {_this.state.title}

                                {((cookies.get('sopotniki_volunteer').role === 1) || _this.state.postUserId === JSON.parse(sessionStorage.getItem('sopotniki_me')).id) &&
                                <div className="pull-right editPostBtnContainer">

                                    <ButtonGroup>
                                        <Link to={'/objave/urejanje/' + _this.state.postId}
                                              className="btn btn-info text-white btn-sm"><i
                                            className="fa fa-pencil"></i> Uredi</Link>

                                        <a onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            _this.setState({showConfirmationDialog: true});

                                        }} className="btn btn-danger text-white btn-sm"><i
                                            className="fa fa-trash"></i> Izbriši</a>
                                    </ButtonGroup>


                                </div>
                                }

                            </div>
                            <div className="author d-inline">
                                <img src={_this.state.userImage} className="avatar"/>
                                <span><b>{_this.state.userName}</b></span> <span className="pl-2"><i
                                className="fa fa-calendar pr-1"></i>{_this.state.createdAtDate} ob {_this.state.createdAtTime}</span>
                            </div>

                            {_this.state.postImg &&
                            <Loader
                                className="pt-2 pb-2"
                                show={_this.state.loadingInProgress}
                                message={spinner}
                                contentBlur={0}
                                backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.6)'}}>

                                <a className="d-block"
                                   data-lightbox={_this.state.postId + '_gallery'}
                                   href={config.apiRoot + config.actions.getFile + btoa(encodeURI('https://drive.google.com/thumbnail?authuser=0&sz=w1024&id=' + _this.state.file))}>

                                    <img src={_this.state.postImg} onLoad={(e) => {
                                        _this.setState({
                                            preloaderImageLoaded: true,
                                            loadingInProgress: false
                                        })
                                    }} className="d-none"/>

                                    <div className={_this.state.preloaderImageLoaded ? 'bg loaded' : 'bg'}
                                         style={{
                                             backgroundImage: 'url(' + _this.state.postImg + ')',
                                             overflow: 'hidden'
                                         }}>

                                        <div
                                            className={_this.state.preloaderImageLoaded ? 'preloaderImage loaded' : 'preloaderImage'}
                                            style={{
                                                background: 'url(' + _this.state.postPreloadImg + ') center center no-repeat',
                                                overflow: 'hidden'
                                            }}/>

                                    </div>

                                </a>
                            </Loader>
                            }

                            <div className="description"
                                 dangerouslySetInnerHTML={{__html: decodeURI(_this.state.content)}}>

                            </div>

                            {_this.state.renderedAttachments.length > 0 &&
                            <div>
                                <div className="row d-flex flex-wrap">
                                    {_this.state.renderedAttachments}
                                </div>
                            </div>
                            }
                        </div>


                    {_this.state.renderedComments}

                </div>

                <div className="mt-2 mb-3">
                    <hr/>
                </div>


                <SweetAlert
                    show={_this.state.showConfirmationDialog}
                    title="Brisanje"
                    text={"Ali ste prepričani, da želite izbrisati to objavo z naslovom " + _this.state.title + "?"}
                    onConfirm={() => {
                        _this.onConfirmedDelete();
                        _this.setState({showConfirmationDialog: false})
                    }
                    }
                    showCancelButton={true}
                />


            </div>
        )
    }
}


export default PostItem
