import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import FullCalendar from 'fullcalendar-reactwrapper-with-scheduler'
import TimeAgo from 'react-timeago'
import sloveneStrings from 'react-timeago/lib/language-strings/sl'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import moment from 'moment';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from 'classnames';
import {
    Alert,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col,
    Row,
    Form,
    InputGroup,
    InputGroupAddon,
    FormGroup,
    Label,
    Input,
    FormText,
    TabContent,
    TabPane,
    Nav,
    NavLink,
    NavItem
} from 'reactstrap'
import _ from 'lodash'
import Api_ from "../service/api";
import Loader from "react-loader-advanced";
import $ from 'jquery';
import Cookies from "universal-cookie";
import config from './../config'
import ReactGA from 'react-ga';

const Api = new Api_();
const formatter = buildFormatter(sloveneStrings);
const cookies = new Cookies();
let calendarEventsLoading = false;

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.fullCalendar = React.createRef();
        this.state = {
            eventModalVisible: false,
            selectedDate: '',
            selectedDay: '',
            selectedTimeAgoDate: '',

            calendarYear: '',
            calendarMonth: '',

            defaultDate: moment(new Date()).format('YYYY-MM-DD'),

            events: [
                {
                    title: 'All Day Event',
                    start: '2018-11-22'
                },
            ],

            unitsVolunteers: [],
            otherVolunteer: null,
            loadingInProgress: false,
            activeTab: '1'
        };

        this.toggleEventModal = this.toggleEventModal.bind(this);
        this.confirmNewEvent = this.confirmNewEvent.bind(this);
        this.updateCalendarDate = this.updateCalendarDate.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.getOtherVolunteers = this.getOtherVolunteers.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });

            if(tab === "2") {
                $('#otherVolunteerSelect').trigger("change");
            }
        }
    }


    componentDidMount() {
        const _this = this;

        ReactGA.pageview("/koledar");

        setTimeout(function () {
            _this.getEvents();
            _this.getOtherVolunteers();
        }, 100);


    }

    componentDidUpdate() {
        const _this = this;

    }

    componentWillUnmount() {

    }

    updateCalendarDate(calendarYear, calendarMonth) {
        const _this = this;

        _this.setState({
            calendarYear: calendarYear,
            calendarMonth: calendarMonth,
        })
    }

    getEvents() {
        const _this = this;

        if (_this.state.loadingInProgress) {
            return false
        }

        _this.setState({loadingInProgress: true});

        let data = {
            unit_id: sessionStorage.getItem('sopotniki_selected_unit_id'),
            year: moment(_this.state.defaultDate, 'YYYY-MM-DD').year(),
            month: moment(_this.state.defaultDate, 'YYYY-MM-DD').month() + 1
        }

        Api.getEvents(data, function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            _this.setState({loadingInProgress: false});

            if (dataRetrieved) {
                //console.log(responseData.data);

                let events = [];

                _.each(responseData.data, function (key, index) {

                    if (key.volunteer) {
                        events.push(
                            {
                                title: key.volunteer.first_name + ' ' + key.volunteer.last_name,
                                image: key.volunteer.profile_image ? config.apiRoot + config.actions.getFile + btoa(encodeURI("https://drive.google.com/thumbnail?authuser=0&sz=w40&id=" + key.volunteer.profile_image)) : key.volunteer.internal_app_image_url,
                                start: moment(key.date).format('YYYY-MM-DD'),
                                created_at: key.created_at,
                                index: _.find(events, {start: moment(key.date).format('YYYY-MM-DD')}) ? '2' : '1'
                            }
                        );



                        // let testarray = document.getElementsByClassName("currentclass");
                        // for(let i = 0; i < testarray.length; i++)
                        // {
                        //     testarray[i].className += "classtobeadded";
                        // }

                        // setTimeout(function () {
                        //     $('.fc-day[data-date="' + moment(key.date).format('YYYY-MM-DD') + '"]').addClass('full');
                        // },10);

                    }
                    // console.log(key);
                    // console.log(events);
                });



                setTimeout(function () {
                    _this.state.events = events;
                    _this.forceUpdate();
                    calendarEventsLoading = false;
                }, 10)


            } else {
                _this.setState({errorResponse: true});
                console.log("Something went wrong");
            }

        });

    }

    toggleEventModal() {
        const _this = this;

        _this.setState({
            eventModalVisible: !_this.state.eventModalVisible,
            otherVolunteer: null
        });
    }

    getOtherVolunteers() {
        const _this = this;



        if (cookies.get('sopotniki_volunteer').role === 1 || cookies.get('sopotniki_volunteer').role === 2 || cookies.get('sopotniki_volunteer').role === 3) {
            Api.getVolunteers(sessionStorage.getItem('sopotniki_selected_unit_id'), function (dataRetrieved, responseData) {
                console.log("\n\n------");
                console.log(dataRetrieved);
                console.log(responseData);
                console.log("------\n\n");

                if (dataRetrieved) {
                    _this.setState({
                        unitsVolunteers: responseData.data
                    });

                } else {
                    console.log("Something went wrong");
                }

            });
        }

    }

    confirmNewEvent() {
        const _this = this;

        let originalDate = moment(_this.state.selectedDate, 'D. M. YYYY');
        originalDate.set({h: '12', m: '00'});
        console.log(moment(originalDate).valueOf());

        let data = {
            unit_id: sessionStorage.getItem('sopotniki_selected_unit_id'),
            volunteers_id: (_this.state.activeTab === '2' && _this.state.otherVolunteer !== null) ? _this.state.otherVolunteer : cookies.get('sopotniki_volunteer').userId,
            date: moment(originalDate).valueOf()
        };

        if(_this.state.activeTab === '2' && _this.state.otherVolunteer === null) {
            alert("Izberite prostovoljca, ki ga želite vpisati v koledar!");
            return
        }

        console.log("_this.state.selectedDate " + moment(moment(_this.state.selectedDate, 'D. M. YYYY')).valueOf());

        Api.setDailyReport(data, function (dataRetrieved, responseData) {
                console.log("\n\n------");
                console.log(dataRetrieved);
                console.log(responseData);
                console.log("------\n\n");

                if (dataRetrieved) {
                    toast.success("Uspešno ste se javili za prostovoljstvo. Hvala!");
                    _this.setState({eventModalVisible: false});

                    _this.getEvents();
                } else {
                    toast.error("Pri prijavi za prostovoljstvo je prišlo do težave - " + responseData);
                }
            });
        // .then(function (date) {
        //     console.log('******');
        //     console.log('setDailyReport');
        //     console.log(date);
        //     console.log('******');
        //
        //     toast.success("Uspešno ste se javili za prostovoljstvo. Hvala!");
        //     _this.setState({eventModalVisible: false});
        //
        //     _this.getEvents();
        //
        // })
        // .catch(function (error) {
        //     _this.setState({eventModalVisible: false});
        //     toast.error("Pri prijavi za prostovoljstvo je prišlo do težave - " + error);
        //
        // });


    }

    render() {
        const _this = this;
        const spinner = <i className="text-black-50 fa fa-2x fa-circle-o-notch fa-spin fa-fw"></i>;

        return (

            <div>

                <Header/>


                <div className="calendar-header">
                    <section className="container">

                    </section>
                </div>


                <div className="calendar-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <h3 className="mt-4 mb-4">Koledar dežurstev</h3>

                                <Loader
                                    className="pt-2 pb-2"
                                    show={_this.state.loadingInProgress}
                                    message={spinner}
                                    contentBlur={0}
                                    backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.6)'}}>


                                    <a className="btn btn-primary pull-left text-white" onClick={() => {

                                        _this.setState({defaultDate: moment(_this.state.defaultDate, 'YYYY-MM-DD').subtract(1, 'months').format('YYYY-MM-DD')});

                                        setTimeout(function () {
                                            _this.getEvents();
                                        }, 10);


                                    }}><i className="fa fa-chevron-left"></i></a>

                                    <a className="btn btn-primary pull-right text-white" onClick={() => {

                                        _this.setState({defaultDate: moment(_this.state.defaultDate, 'YYYY-MM-DD').add(1, 'months').format('YYYY-MM-DD')});

                                        setTimeout(function () {
                                            _this.getEvents();
                                        }, 10);

                                    }}><i className="fa fa-chevron-right"></i></a>

                                    <div className="clearfix"></div>


                                    <FullCalendar
                                        ref={_this.fullCalendar}
                                        header={{
                                            left: '',
                                            center: 'title',
                                            right: ''
                                        }}
                                        defaultDate={_this.state.defaultDate}
                                        navLinks={false} // can click day/week names to navigate views
                                        editable={false}
                                        eventLimit={false} // allow "more" link when too many events
                                        events={_this.state.events}
                                        eventOrder={'created_at'}
                                        schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                                        locale='sl'
                                        lang='sl'
                                        height='auto'
                                        dayClick={(date, jsEvent, view, resourceObj) => {

                                            //console.log(date.format('MMM D, YYYY'));


                                            console.log(date.format('M'));
                                            console.log(moment(_this.state.defaultDate).format('M'));


                                            // if (cookies.get('sopotniki_volunteer').role !== 1 && moment(date).isBefore(moment(new Date()))) {
                                            //     toast.info("Dogodkov v preteklosti ni mogoče dodajati!");
                                            //     return;
                                            // }

                                            if (moment(_this.state.defaultDate).format('M') !== date.format('M')) {
                                                toast.info("Za vpis v drugi mesec, najprej s klikom na gumb z oznako < ali > zamenjajte mesec!");
                                                return;
                                            }

                                            _this.setState({
                                                selectedDate: date.format('D. M. YYYY')
                                            });


                                            let eventsPerSameDay = 0;

                                            for (let i = 0; i < _this.state.events.length; i++) {

                                                if (moment(date.format('YYYY-MM-DD')).isSame(moment(this.state.events[i].start).format('YYYY-MM-DD'))) {
                                                    console.log("Edit event");

                                                    let selectedUnit = _.filter(JSON.parse(sessionStorage.getItem('sopotniki_me')).units, {'id': parseInt(sessionStorage.getItem('sopotniki_selected_unit_id'))})[0];

                                                    console.log("eventsPerSameDay ", eventsPerSameDay);
                                                    console.log("selectedUnit.cars.length ", selectedUnit.cars.length);

                                                    if(eventsPerSameDay >= selectedUnit.cars.length-1) {

                                                        _this.setState({
                                                            selectedTimeAgoDate: date.format('MMM D, YYYY'),
                                                            selectedDay: date.locale("sl").format('dddd'),
                                                            eventModalVisible: false
                                                        });

                                                        toast.info("V " + _this.state.selectedDay + ", " + _this.state.selectedDate + ", termin ni več prost!");
                                                        break;

                                                    } else {

                                                        console.log("Create next new event");
                                                        _this.setState({
                                                            selectedTimeAgoDate: date.format('MMM D, YYYY'),
                                                            selectedDay: date.locale("sl").format('dddd'),
                                                            eventModalVisible: true
                                                        });


                                                    }

                                                    eventsPerSameDay++;

                                                    //break;

                                                } else if (i == this.state.events.length - 1) {
                                                    console.log("Create new event");
                                                    _this.setState({
                                                        selectedTimeAgoDate: date.format('MMM D, YYYY'),
                                                        selectedDay: date.locale("sl").format('dddd'),
                                                        eventModalVisible: true
                                                    });

                                                }
                                            }

                                            if(_this.state.events.length === 0) {
                                                _this.state.selectedTimeAgoDate = date.format('MMM D, YYYY');
                                                _this.state.selectedDay = date.locale("sl").format('dddd');
                                                _this.state.eventModalVisible= true;
                                                _this.forceUpdate()
                                            }

                                        }}
                                        viewRender={(view, event, test) => {


                                            // if(!calendarEventsLoading) {
                                            //     _this.setState({
                                            //         calendarYear: moment(view.intervalStart._i).year(),
                                            //         calendarMonth: moment(view.intervalStart._i).month() + 1
                                            //     });
                                            // }
                                            //
                                            // calendarEventsLoading = true;

                                        }}
                                        eventRender={(event, element, view) => {

                                            let selectedUnit = _.filter(JSON.parse(sessionStorage.getItem('sopotniki_me')).units, {'id': parseInt(sessionStorage.getItem('sopotniki_selected_unit_id'))})[0];

                                            console.log("selectedUnit.cars.length", selectedUnit.cars.length);

                                            $('.fc-day[data-date="' + moment(event.start).format('YYYY-MM-DD') + '"]').addClass('full');

                                            if(selectedUnit.cars.length > 1) {
                                                return '<div class="p-2 eventItem" style="height: 48px"><img class="img img-responsive rounded-circle w-25 mr-2" src="' + event.image + '" /><span class="badge">' + event.index + '</span><small>' + event.title + '</small></div>';
                                            } else {
                                                return '<div class="p-2 eventItem" style="height: 48px"><img class="img img-responsive rounded-circle w-25 mr-2" src="' + event.image + '" /><small>' + event.title + '</small></div>';
                                            }

                                        }}
                                        eventAfterAllRender={(calEvent, jsEvent, view) => {
                                            //calendarEventsLoading = false;
                                        }}
                                        buttonText={{
                                            'today': 'današnji dan',
                                            'month': 'mesec',
                                            'week': 'teden',
                                            'day': 'dan'
                                        }}
                                    />


                                </Loader>

                            </div>
                        </div>
                    </div>
                </div>


                <Footer/>


                <Modal isOpen={_this.state.eventModalVisible} toggle={this.toggleEventModal} backdrop="static">
                    <ModalHeader toggle={this.toggleEventModal}>Vpis v e-koledar prostovoljstva</ModalHeader>
                    <ModalBody>


                        {(cookies.get('sopotniki_volunteer').role === 4) &&
                            <Alert color="info">
                                <b>{cookies.get('sopotniki_volunteer').volunteerName}</b>, v e-koledar prostovoljstva se
                                želiš vpisati za dan:&nbsp;
                                <b>{_this.state.selectedDay}, {_this.state.selectedDate} (<TimeAgo
                                    date={_this.state.selectedTimeAgoDate} formatter={formatter}/>)</b>.
                                <br/>Preden bo tvoj vpis zabeležen, moraš izbiro potrditi.
                            </Alert>
                        }

                        {(cookies.get('sopotniki_volunteer').role !== 4) &&

                            <div>

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: _this.state.activeTab === '1' })}
                                            onClick={() => { _this.toggleTab('1'); }}
                                        >
                                            Prijavi sebe
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: _this.state.activeTab === '2' })}
                                            onClick={() => { _this.toggleTab('2'); }}
                                        >
                                            Prijavi drugega prostovoljca
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={_this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col sm="12">

                                                <Alert color="info">
                                                    <b>{cookies.get('sopotniki_volunteer').volunteerName}</b>, v e-koledar prostovoljstva se
                                                    želiš vpisati za dan:&nbsp;
                                                    <b>{_this.state.selectedDay}, {_this.state.selectedDate} (<TimeAgo
                                                        date={_this.state.selectedTimeAgoDate} formatter={formatter}/>)</b>.
                                                    <br/>Preden bo tvoj vpis zabeležen, moraš izbiro potrditi.
                                                </Alert>

                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row>
                                        <Col sm="12">

                                            <Alert color="info">
                                                <div>
                                                    V e-koledar prostovoljstva želiš vpisati prostovoljko/-ca:

                                                    {(_this.state.unitsVolunteers.length > 0) &&
                                                        <select id="otherVolunteerSelect" className="d-block mt-2 mb-2 w-100 form-control" onChange={(e) => {_this.setState({otherVolunteer: e.target.value})}}>
                                                            <option value="-1">izberi prostovoljko/-ca</option>
                                                            {_this.state.unitsVolunteers.map((item, key) =>
                                                                <option item={item} key={item.id} value={item.id}>{item.first_name} {item.last_name}</option>
                                                            )}
                                                        </select>
                                                    }

                                                    za dan:&nbsp;
                                                    <b>{_this.state.selectedDay}, {_this.state.selectedDate} (<TimeAgo
                                                        date={_this.state.selectedTimeAgoDate} formatter={formatter}/>)</b>.
                                                    <br/>Preden bo tvoj vpis zabeležen, moraš izbiro potrditi.
                                                </div>
                                            </Alert>

                                        </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>

                            </div>

                        }




                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" color="default" className="mr-auto"
                                onClick={this.toggleEventModal}>Prekliči</Button>
                        <a className="btn-pill btn-pill-success customFadeInUp text-white"
                           onClick={this.confirmNewEvent}><i
                            className="fa fa-check"></i> Potrdi vpis</a>{' '}
                    </ModalFooter>
                </Modal>

                <ToastContainer/>

            </div>
        )
    }
}

export default Calendar
