import React from 'react'
import moment from 'moment';
import Header from './Header'
import Footer from './Footer'
import bgImg from '../images/unsplash/aranxa-esteve-130749.jpg'
import _ from 'lodash';
import avatar from '../images/uifaces/10.jpg'
import Api_ from "../service/api";
import PubSub from "pubsub-js";
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {EditorState, convertToRaw, convertFromHTML, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from "draftjs-to-html";
import Loader from 'react-loader-advanced';
import {withSwalInstance} from 'sweetalert2-react';
import swal from 'sweetalert2';
import CKEditor from 'ckeditor4-react';
import config from "../config";

const Api = new Api_();
const SweetAlert = withSwalInstance(swal);

CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.11.3/full/ckeditor.js';

class Comment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postId: this.props.postId ? this.props.postId : null,
            commentsData: this.props.commentsData ? this.props.commentsData : [],
            commentsDataHtmlString: '',
            addCommentFormVisible: false,
            newCommentBody: '',
            replyCommentParentId: null,
            loadingInProgress: false,
            showConfirmationDialog: false,
            selectedCommentId: null
        };

        window.onDelete = this.onDelete.bind(this);
        window.onReply = this.onReply.bind(this);


        this.renderComments = this.renderComments.bind(this);
        this.renderCommentItem = this.renderCommentItem.bind(this);

        this.onDelete = this.onDelete.bind(this);

        this.addComment = this.addComment.bind(this);
        this.onEditorChange = this.onEditorChange.bind(this);

        this.publishNewComment = this.publishNewComment.bind(this);

        //console.log(this.props);
    }


    onEditorChange = (evt) => {
        const _this = this;

        let newContent = evt.editor.getData();

        this.setState({
            newCommentBody: newContent
        });
    }


    componentWillReceiveProps(props) {
        const _this = this;

        _this.setState({
            postId: props.postId ? props.postId : null,
            commentsData: props.commentsData ? props.commentsData : [],
            commentsDataHtmlString: ''
        });

        _this.renderComments();
    }

    componentDidMount() {
        const _this = this;

        //alert("here!");


        _this.setState({
            commentsDataHtmlString: ''
        });


        _this.renderComments();

    }

    renderComments() {
        const _this = this;

        let commentsGroupedData = _this.getTree(_this.state.commentsData);

        let threadHtml = '';

        _this.setState({
            commentsDataHtmlString: ''
        });

        _.each(commentsGroupedData, function (val, idx) {
            console.log("idx", idx);
            console.log("val", val);

            // level 0
            threadHtml += '<div class="threadItem">' + _this.renderCommentItem(val);
            // commentThread.push(
            //     <div key={idx}>{val.content}</div>
            // );


            if (val.children.length > 0) {
                // level 1
                _.each(val.children, function (valLevel1, idxLevel1) {
                    threadHtml += '<div class="threadItemLevel1">' + _this.renderCommentItem(valLevel1);

                    if (valLevel1.children.length > 0) {

                        // level 2
                        _.each(valLevel1.children, function (valLevel2, idxLevel2) {
                            threadHtml += '<div class="threadItemLevel2">' + _this.renderCommentItem(valLevel2);

                            if (valLevel2.children.length > 0) {

                                // level 3
                                _.each(valLevel2.children, function (valLevel3, idxLevel3) {
                                    threadHtml += '<div class="threadItemLevel3">' + _this.renderCommentItem(valLevel3);

                                    if (valLevel3.children.length > 0) {

                                        // level 4
                                        _.each(valLevel3.children, function (valLevel4, idxLevel4) {
                                            threadHtml += '<div class="threadItemLevel4">' + _this.renderCommentItem(valLevel4) + '</div>'; // close level 4
                                        });

                                    }


                                    threadHtml += '</div>'; // close level 3


                                });

                            }

                            threadHtml += '</div>'; // close level 2

                        });

                    }

                    threadHtml += '</div>'; // close level 1

                });
            }

            threadHtml += '</div>'; // close level 0

            console.log("threadHtml", threadHtml);

            if (idx === commentsGroupedData.length - 1) {

                _this.setState({
                    commentsDataHtmlString: threadHtml
                });

            }

        });

    }

    renderCommentItem(data) {
        const _this = this;

        let commentItem = '';
        let commentUserId = data.user_id;
        let loggedUserId = JSON.parse(sessionStorage.getItem('sopotniki_me')).id;
        let loggedUserRole = JSON.parse(sessionStorage.getItem('sopotniki_me')).user_role;

        let profileImageUrl = data.user.profile_image ? (config.apiRoot + config.actions.getFile + btoa(encodeURI('https://drive.google.com/thumbnail?authuser=0&sz=w40&id=' + data.user.profile_image))) : (data.user.internal_app_image_url ? data.user.internal_app_image_url : 'https://placehold.it/300x300&text=' + data.user_id);

        console.log("commentUserId", commentUserId);
        console.log("loggedUserId", loggedUserId);
        console.log("loggedUserRole", loggedUserRole);

        commentItem += '<div class="commentItem">';

        commentItem += '<div class="commentAuthor">';
        commentItem += '<div class="avatar">';
        commentItem += '<img src="' + profileImageUrl + '"/>';
        commentItem += '</div>';
        commentItem += '<div class="name">';
        commentItem += '<span>' + data.user.first_name + ' ' + data.user.last_name + '</span>';
        commentItem += '</div>';
        commentItem += '<div class="date">';
        commentItem += '<span class="pl-2">' + moment(data.updated_at).format('DD. MM. YYYY ob HH:mm') + '</span>';
        commentItem += '</div>';
        commentItem += '</div>';
        commentItem += '<div class="commentText">' + decodeURI(data.content) + '</div>';
        commentItem += '<div class="actions">';
        commentItem += '<a class="btn btn-sm text-primary btnReply" onClick={window.onReply(' + data.id + ',' + data.post_id + ')}><i class="fa fa-reply"></i> Odgovori</a>';
        commentItem += ((commentUserId === loggedUserId || loggedUserRole === 4) ? '<a class="btn btn-sm text-danger btnDelete" onClick={window.onDelete(' + data.id + ')}><i class="fa fa-trash"></i> Izbriši</a>' : '');
        commentItem += '</div>';

        commentItem += '</div>';

        return commentItem

    }

    onDelete(id) {
        let _this = this;

        _this.setState({
            selectedCommentId: id,
            showConfirmationDialog: true
        });
    }

    onConfirmedDelete() {
        let _this = this;
        console.log("delete " + _this.state.selectedCommentId);

        //console.log("commentsGroupedData", _this.getTree(_this.state.commentsData));

        _this.setState({
            showConfirmationDialog: false
        });

        console.log("_this.state.commentsData", _this.state.commentsData);

        let result = _.chain(_this.state.commentsData)
            .filter(function (item) {
                if (item.id === _this.state.selectedCommentId) {
                    return true
                }
            })
            .each(function (item) {
                console.log(item);

            })
            .value()

        console.log("result", result);

        let data = {
            unit_id: sessionStorage.getItem('sopotniki_selected_unit_id'),
            ids: []
        };


        data.ids.push(_this.state.selectedCommentId);

        if (result[0]) {
            _.each(result[0].children, function (item) {

                data.ids.push(item.id);

                _.each(item.children, function (item2) {
                    data.ids.push(item2.id);

                    _.each(item2.children, function (item3) {
                        data.ids.push(item3.id);

                        _.each(item3.children, function (item4) {
                            data.ids.push(item4.id);
                        });
                    });
                });
            });
        }


        console.log(data)


        Api.deleteComment(data, function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");


            if (dataRetrieved) {

                _this.setState({selectedCommentId: null});
                _this.props.getCommentsData();

            } else {
                console.log("Something went wrong");
            }
        });


    }

    onReply(commentId, postId) {
        let _this = this;

        console.log("commentId", commentId);
        console.log("postId", postId);

        _this.setState({
            replyCommentParentId: commentId,
            postId: postId
        });

        _this.forceUpdate(function () {
            _this.addComment();
        });
    }

    componentWillUnmount() {

    }

    getTree(arr) {

        let nodes = {};
        let results = arr.filter(function (obj) {
            let id = obj["id"],
                parentId = obj["parent_comment_id"];

            nodes[id] = _.defaults(obj, nodes[id], {children: []});
            parentId && (nodes[parentId] = (nodes[parentId] || {children: []}))["children"].push(obj);

            return !parentId;
        });

        console.log("results", results);

        return results


    }

    addComment() {
        const _this = this;


        console.log("_this.state.postId", _this.state.postId);

        _this.setState({
            addCommentFormVisible: !_this.state.addCommentFormVisible,
            newCommentBody: ''
        });


    }

    publishNewComment() {
        const _this = this;

        _this.setState({loadingInProgress: true});

        let commentData = {
            unit_id: sessionStorage.getItem('sopotniki_selected_unit_id'),
            post_id: _this.state.postId,
            content: encodeURI(_this.state.newCommentBody)
        };

        if (_this.state.replyCommentParentId !== null) {
            commentData.parent_comment_id = _this.state.replyCommentParentId;
        }

        Api.submitComment(commentData, function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            _this.setState({
                loadingInProgress: false,
                addCommentFormVisible: false
            });

            if (dataRetrieved) {


                _this.props.getCommentsData();


            } else {
                console.log("Something went wrong");
            }
        });

    }


    render() {
        const _this = this;
        const spinner = <i className="text-black-50 fa fa-2x fa-circle-o-notch fa-spin fa-fw"></i>;

        return (

            <div className="row" key={_this.state.postId}>
                <div className="col-md-12 mt-3">
                    <h6>Komentarji</h6>


                    <div className="comments" key={_this.state.postId}
                         dangerouslySetInnerHTML={{__html: _this.state.commentsDataHtmlString}}></div>


                    <a className="btn btn-link text-primary" onClick={_this.addComment}><i
                        className="fa fa-commenting"></i> Napiši komentar</a>


                    <Modal isOpen={_this.state.addCommentFormVisible} toggle={this.addComment} backdrop="static">
                        <ModalHeader toggle={this.addComment}>Napiši komentar</ModalHeader>
                        <ModalBody>

                            <Loader
                                className="pt-2 pb-2"
                                show={_this.state.loadingInProgress}
                                message={spinner}
                                contentBlur={0}
                                backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.6)'}}>

                                <CKEditor
                                    id="postBody"
                                    className="mb-4 mt-0"
                                    data={_this.state.newCommentBody}
                                    config={{
                                        lang: 'si',
                                        toolbar: [
                                            ['Bold', 'Italic', 'Underline', 'StrikeThrough', '-', 'Undo', 'Redo', '-', 'Link', 'Unlink', '-', 'Smiley', 'Iframe']
                                        ]
                                    }}
                                    onChange={evt => _this.onEditorChange(evt)}
                                />

                            </Loader>

                        </ModalBody>
                        <ModalFooter>
                            <Button size="sm" color="default" className="mr-auto"
                                    onClick={this.addComment}>Prekliči</Button>
                            <a className="btn-pill btn-pill-success customFadeInUp text-white"
                               onClick={this.publishNewComment}><i
                                className="fa fa-check"></i> Objavi komentar</a>
                        </ModalFooter>
                    </Modal>


                </div>

                <SweetAlert
                    show={_this.state.showConfirmationDialog}
                    title="Brisanje"
                    text="Ali ste prepričani, da želite izbrisati izbran komentar? Izbrisani bodo tudi vsi odgovori na ta komentar."
                    onConfirm={() => {
                        _this.onConfirmedDelete();
                        _this.setState({showConfirmationDialog: false})
                    }
                    }
                    showCancelButton={true}
                />

            </div>
        )
    }
}


export default Comment
