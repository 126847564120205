import React from 'react'
import Logo from '../images/logotip.svg'
import Api_ from '../service/api'
import {Link, withRouter} from 'react-router-dom'
import Loader from "react-loader-advanced";
import {Alert} from "reactstrap";

const Api = new Api_();


class RegistrationConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: this.getUrlPram('key', this.props.location.search),
            username: '',
            password: '',
            passwordRepeat: '',
            firstName: '',
            loadingInProgress: true,
            errorResponse: false,
            passwordMismatch: false,
            successResponse: false
        };

        console.log(this.props.location);

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const _this = this;

        let inputName = e.target.name.split('.')[0];
        let inputSubName = e.target.name.split('.')[1];
        let inputSubSubName = e.target.name.split('.')[2];
        let inputValue = e.target.value;

        let statusCopy = Object.assign({}, this.state);

        if (inputSubSubName) {
            statusCopy[inputName][inputSubName][inputSubSubName] = inputValue;
        } else if (typeof inputSubSubName === 'undefined' && inputSubName) {
            statusCopy[inputName][inputSubName] = inputValue;
        } else {
            statusCopy[inputName] = inputValue;
        }

        _this.setState(statusCopy);
    }



    componentDidMount() {
        const _this = this;

        document.body.classList.add('signin-page');


        let data = {
            key: _this.state.key
        };

        Api.checkKey(data, function (dataRetrieved, responseData){
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            if (dataRetrieved) {
                _this.setState({
                    loadingInProgress: false,
                    firstName: responseData.data.volunteer.first_name,
                    email: responseData.data.volunteer.email
                });
            } else {
                _this.setState({loadingInProgress: false});
                console.log("Something went wrong", responseData);
            }
        });
    }

    componentWillUnmount() {
        document.body.classList.remove('signin-page');
    }

    onSubmit() {
        const _this = this;

        if(_this.state.password !== _this.state.passwordRepeat || _this.state.password === '') {
            _this.setState({passwordMismatch: true});
            return false;
        }

        _this.setState({loadingInProgress: true});

        let data = {
            email: _this.state.email,
            password: _this.state.password,
            key: _this.state.key
        };

        Api.registrationConfirmation(data, function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            _this.setState({loadingInProgress: false});

            if (dataRetrieved) {
                _this.setState({successResponse: true});
                //_this.props.history.push('/prijava');
            } else {
                console.log("Something went wrong", responseData);
            }
        });

    }

    getUrlPram(name, url) {
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        let regexS = "[\\?&]" + name + "=([^&#]*)";
        let regex = new RegExp(regexS);
        let results = regex.exec(url);
        return results == null ? null : results[1];
    }

    render() {
        const _this = this;
        const spinner = <i className="text-black-50 fa fa-2x fa-circle-o-notch fa-spin fa-fw"></i>;

        return (
            <div className="signin-page">
                <div className="wrapper">
                    <div className="header">
                        <img src={Logo} className="logo img-responsive"/>
                        <div className="portalName badge badge-primary">PROSTOVOLJSKI PORTAL</div>
                    </div>


                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-6 offset-3">


                                <Loader
                                    className="pt-2 pb-2"
                                    show={_this.state.loadingInProgress}
                                    message={spinner}
                                    contentBlur={0}
                                    backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.6)'}}>
                                    <form autoComplete="off">


                                        {_this.state.errorResponse &&
                                        <div className="row">
                                            <div className="col-sm-12 mb-2">
                                                <Alert color="danger">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <i className="pt-2 fa fa-exclamation-triangle fa-2x"></i>
                                                        </div>
                                                        <div className="col-10 text-left">
                                                            Pri potrditvi registracije je prišlo do napake.<br/><br/>
                                                            <small>V primeru težav se obrnite na <a
                                                                href="mailto:info@sopotniki.org">info@sopotniki.org</a>
                                                            </small>
                                                            .
                                                        </div>
                                                    </div>
                                                </Alert>
                                            </div>
                                        </div>
                                        }

                                        {_this.state.passwordMismatch &&
                                        <div className="row">
                                            <div className="col-sm-12 mb-2">
                                                <Alert color="danger">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <i className="pt-2 fa fa-exclamation-triangle fa-2x"></i>
                                                        </div>
                                                        <div className="col-10 text-left">
                                                            Gesli nista izbrani ali pa se ne ujemata. Določite si varno geslo in ga ponovite.<br/><br/>
                                                            <small>V primeru težav se obrnite na <a
                                                                href="mailto:info@sopotniki.org">info@sopotniki.org</a>
                                                            </small>
                                                            .
                                                        </div>
                                                    </div>
                                                </Alert>
                                            </div>
                                        </div>
                                        }

                                        {_this.state.successResponse &&
                                        <div className="row">
                                            <div className="col-sm-12 mb-2">
                                                <Alert color="success">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <i className="pt-2 fa fa-check-circle-o fa-2x"></i>
                                                        </div>
                                                        <div className="col-10 text-left">
                                                            Uspešno ste se registrirali.<br/><br/>
                                                            V prostovoljski portal se lahko prijavite  <Link to="/prijava">TUKAJ</Link>.
                                                        </div>
                                                    </div>
                                                </Alert>
                                            </div>
                                        </div>
                                        }


                                        {!_this.state.successResponse &&
                                        <div>
                                        <div className="mb-4 text-left"><h5>Potrditev registracije</h5></div>

                                        <div className="alert alert-info">
                                            Živjo, {_this.state.firstName}! Za dokončanje registracije moraš določiti
                                            še geslo in ga ponoviti.
                                        </div>

                                        <div className="form-field">
                                            <input autoComplete="off" type="email" disabled={true} placeholder="e-mail" id="email" name="email"
                                                   value={_this.state.email}
                                                   onChange={_this.onChange}/>
                                        </div>
                                        <div className="form-field">
                                            <input autoComplete="off" type="password" placeholder="geslo" id="password" name="password"
                                                   value={_this.state.password}
                                                   onChange={_this.onChange}/>
                                        </div>
                                        <div className="form-field">
                                            <input autoComplete="off" type="password" placeholder="ponovljeno geslo" id="passwordRepeat" name="passwordRepeat"
                                                   value={_this.state.passwordRepeat}
                                                   onChange={_this.onChange}/>
                                        </div>

                                        <div className="form-action">
                                            <input type="submit" className="btn-shadow btn-shadow-primary"
                                                   value="Potrdi registracijo"
                                                   onClick={(e) => {
                                                       e.preventDefault();
                                                       _this.onSubmit()
                                                   }}/>
                                        </div>
                                        </div>
                                        }
                                        {/*<div className="form-bottom">*/}
                                        {/*<a href="recover-password.html">Si pozab?</a>*/}
                                        {/*</div>*/}
                                    </form>

                                </Loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(RegistrationConfirmation)
