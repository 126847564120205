import React from 'react'
import moment from 'moment';
import Header from './Header'
import Footer from './Footer'
import bgImg from '../images/unsplash/aranxa-esteve-130749.jpg'
import _ from 'lodash';
import avatar from '../images/uifaces/10.jpg'
import PubSub from "pubsub-js";
import Api_ from "../service/api";
import $ from 'jquery';
import lightbox2 from 'lightbox2';
import config from './../config'

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const Api = new Api_();

class Attachment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postId: this.props.postId ? this.props.postId : null,
            dataKey: this.props.dataKey ? this.props.dataKey : null,
            fileName: this.props.attachmentData ? this.props.attachmentData.file : null,
            mimeType: this.props.attachmentData ? this.props.attachmentData.mime_type : null,
            attachmentType: this.props.attachmentData ? this.props.attachmentData.type : null,
            canDelete: this.props.canDelete ? this.props.canDelete : false,
            attachmentId: this.props.attachmentData ? this.props.attachmentData.id : null,
            headerImage: null
        };

        this.onDeleteAttachment = this.onDeleteAttachment.bind(this);
        this.selectAsHeaderImage = this.selectAsHeaderImage.bind(this);

        console.log("this.props.dataKey", this.props.dataKey);
    }


    componentDidMount() {
        const _this = this;
    }

    componentWillUnmount() {

    }

    selectAsHeaderImage(e) {

        $('.headerImageBtn.btn-success').removeClass('btn-success').addClass('btn-secondary');
        $(e.target).removeClass('btn-secondary').addClass('btn-success');

        PubSub.publish('HEADER_IMAGE_SELECTED', this.state.fileName);
    }

    onDeleteAttachment() {
        const _this = this;

        _this.setState({loadingInProgress: true});

        Api.deletePostAttachment(this.state.attachmentId, function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            if (dataRetrieved) {
                _this.setState({loadingInProgress: false});
                PubSub.publish('ATTACHMENT_REMOVED');
            } else {
                _this.setState({loadingInProgress: false});
                console.log("Something went wrong", responseData);
            }
        });
    }

    render() {
        const _this = this;
        if(!_this.state.canDelete && _this.state.attachmentType === 'coverImage') return '';

        return (

            <div className="col-md-4 col-sm-6 col-xs-12 mb-2 mb-sm-0" style={{height: '100%'}}>

                {((_this.state.mimeType === 'image/jpeg' || _this.state.mimeType === 'image/png' || _this.state.mimeType === 'image/gif') && (_this.state.attachmentType === null || (_this.state.attachmentType !== null && _this.state.canDelete))) &&
                <a className="text-center img-thumbnail d-block" style={{'height': '160px', 'overflow': 'hidden', 'padding': '0px'}}
                   data-lightbox={_this.state.postId + '_gallery'}
                   href={config.apiRoot + config.actions.getFile + btoa(encodeURI('https://drive.google.com/thumbnail?authuser=0&sz=w1024&id=' + _this.state.fileName))}>
                    <div style={{
                        backgroundImage: 'url(' + config.apiRoot + config.actions.getFile + btoa(encodeURI('https://drive.google.com/thumbnail?authuser=0&sz=w200-h160&id=' + _this.state.fileName)) + ')',
                        overflow: 'hidden',
                        height: '100%',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center'
                    }}/>
                </a>
                }


                {(_this.state.mimeType !== 'image/jpeg' && _this.state.mimeType !== 'image/png' && _this.state.mimeType !== 'image/gif') &&
                <div>
                    <div className="text-center" style={{'height': '160px', 'overflow': 'hidden'}}>
                        <div className="img-thumbnail w-100 h-100">
                            <i className="fa fa-4x fa-file text-muted pt-5"></i>
                        </div>
                    </div>

                    {!_this.state.canDelete &&
                    <a className="btn btn-sm btn-info mt-2 ml-0 pt-1 pb-1"
                       href={config.apiRoot + config.actions.getFile + btoa(encodeURI('https://drive.google.com/uc?export=download&id=' + _this.state.fileName))}><i
                        className="fa fa-download"></i> Prenesi
                        priponko</a>
                    }

                </div>
                }


                <small>


                    {_this.state.canDelete &&
                    <div>
                        <a className="btn btn-sm btn-danger text-white mt-2 ml-0 pt-1 pb-1 d-block"
                           onClick={(e) => _this.onDeleteAttachment()}><i
                            className="fa fa-trash"></i> {_this.state.attachmentType === 'coverImage' ? 'Izbriši naslovnico' : 'Izbriši priponko'}</a>
                    </div>
                    }

                </small>


            </div>
        )
    }
}


export default Attachment
