import React from 'react'
import moment from 'moment';
import Header from './Header'
import Footer from './Footer'
import bgImg from '../images/unsplash/aranxa-esteve-130749.jpg'
import _ from 'lodash';
import avatar from '../images/uifaces/10.jpg'
import PubSub from "pubsub-js";
import Api_ from "../service/api";
import $ from 'jquery';
import lightbox2 from 'lightbox2';
import ReactGA from "react-ga";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const Api = new Api_();

class StatisticsOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: 0,
            odometer_total: 0,
            volunteer_hours_total: 0,
            reservationCount: 0,
            reservations: [],
            reservationsByTypeToShow: []
        };

        this.overlayDisplayed = this.overlayDisplayed.bind(this);
        this.overlayClosed = this.overlayClosed.bind(this);
        this.gatherReservationsData = this.gatherReservationsData.bind(this);
        this.renderReservations = this.renderReservations.bind(this);
    }

    componentDidMount() {
        const _this = this;
    }

    componentWillUnmount() {

    }

    overlayDisplayed() {
        const _this = this;

        $('.bubbleItem.animated').removeClass("animated");


        Api.getStatistics(function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            if (dataRetrieved) {

                _this.setState({
                    customers: responseData.data.customer,
                    odometer_total: responseData.data.odometer_total,
                    volunteer_hours_total: responseData.data.volunteer_hours_total ? responseData.data.volunteer_hours_total.split(':')[0] : 'ni podatka',
                    reservations: responseData.data.reservation
                });

                _this.gatherReservationsData();


            } else {
                console.log("Something went wrong");
            }
        });


        setTimeout(function () {
            $('.bubbleItem').each(function () {
                $(this).addClass('animated');
            });
        }, 300);

        ReactGA.event({
            category: 'Statistics',
            action: '[7 days statistics viewed]',
            label: JSON.parse(sessionStorage.getItem('sopotniki_me')).volunteers.first_name + ' ' + JSON.parse(sessionStorage.getItem('sopotniki_me')).volunteers.last_name,
            value: 1
        });

    }

    gatherReservationsData() {
        const _this = this;

        let reservationCount = 0;
        let i = 0;

        _.each(_this.state.reservations, function (val, key) {
            console.log(val);
            console.log(key);

            console.log("val length", val.length);

            reservationCount += val.length;

            i++;

            if(i === Object.keys(_this.state.reservations).length) {
                _this.setState({
                    reservationCount: reservationCount
                });
                _this.renderReservations();
            }

        });
    }

    renderReservations() {
        const _this = this;

        let reservationsToShow = [];
        let i = 0;

        _.each(_this.state.reservations, function (val, key) {
            console.log(val);
            console.log(key);

            console.log("val length", val.length);

            let percent = Math.round(val.length / _this.state.reservationCount * 100);
            let label = '';

            if(key === null || key === 'null') {
                label = 'ni podatka';
            } else if(key === 'Storitve (trgovina, banka, pošta, frizer ipd.) ') {
                label = 'storitve';
            } else if(key === 'Dogodek (kulturni, zabavni ipd.)') {
                label = 'dogodek';
            } else {
                label = key.toLowerCase();
            }

            let icon = '';

            switch(label) {
                case 'zdravnik':
                    icon = 'fa-medkit';
                    break;

                case 'storitve':
                    icon = 'fa-shopping-cart';
                    break;

                case 'zdravilišče':
                    icon = 'fa-shower';
                    break;

                case 'dogodek':
                    icon = 'fa-university';
                    break;

                case 'obisk':
                    icon = 'fa-users';
                    break;

                case 'izlet':
                    icon = 'fa-car';
                    break;

                case 'pokopališče':
                    icon = 'fa-fire';
                    break;

                case 'ni podatka':
                    icon = 'fa-ban'
                    break;
            }

            reservationsToShow.push(

                <div className="statisticsRow" key={key} percent={percent}>
                    <div className="value">{percent}%</div>
                    <div className="icon"><i className={"fa " + icon}></i></div>
                    <div className="label">{label}</div>
                </div>

            );

            i++;

            console.log(i);
            console.log(Object.keys(_this.state.reservations).length);
            if(i === Object.keys(_this.state.reservations).length) {

                reservationsToShow = _.reverse(_.sortBy(reservationsToShow, [function (o) {
                    return parseInt(o.props.percent);
                }]));

                _this.setState({
                    reservationsByTypeToShow: reservationsToShow
                });
            }

        });

    }

    overlayClosed() {
        $('.bubbleItem.animated').removeClass("animated");
        $('.bubblesContainer').css({'display': 'none'});
    }


    render() {
        const _this = this;

        return (
            <div className="statisticsOverlay">

                <h1>Zavod Sopotniki</h1>
                <h2>v zadnjih 7 dneh</h2>

                <div className="bubblesContainer">
                    <div className="bubbleItem bubble1 bounceInLeft">
                        <div className="bubbleContent">
                            <h3>Število prevozov</h3>
                            <h4>opravljenih v zadnjih 7 dneh</h4>
                            <div className="bigValue">{_this.state.reservationCount}</div>
                        </div>
                    </div>
                    <div className="bubbleItem bubble2 bounceInLeft">
                        <div className="bubbleContent">
                            <h3>Skupno število uporabnikov</h3>
                            <h4>ki uporabljajo storitev</h4>
                            <div className="bigValue">{_this.state.customers}</div>
                        </div>
                    </div>

                    <div className="bubbleItem bubble3 bounceInUp">
                        <div className="bubbleContent">
                            <h3>Delež prevozov</h3>
                            <h4>glede na glavni namen poti</h4>

                            {_this.state.reservationsByTypeToShow}

                        </div>
                    </div>

                    <div className="bubbleItem bubble4 bounceInRight">
                        <div className="bubbleContent">
                            <h3>Število kilometrov</h3>
                            <h4>opravljenih v zadnjih 7 dneh</h4>
                            <div className="bigValue">{_this.state.odometer_total}</div>
                        </div>
                    </div>
                    <div className="bubbleItem bubble5  bounceInRight">
                        <div className="bubbleContent">
                            <h3>Število prostovoljskih ur</h3>
                            <h4>opravljenih v zadnjih 7 dneh</h4>
                            <div className="bigValue">{_this.state.volunteer_hours_total}</div>
                        </div>
                    </div>

                </div>


            </div>
        )
    }
}


export default StatisticsOverlay
