import React from 'react'
import Logo from '../images/logotip.svg'
import Api_ from '../service/api'
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
    withRouter
} from 'react-router-dom'
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Loader from 'react-loader-advanced';
import Cookies from 'universal-cookie';
import config_ from "../config";
import axios from "axios";

const Api = new Api_();
const cookies = new Cookies();
const config = config_;

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loadingInProgress: false,
            errorResponse: false
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.id]: e.target.value});
    }


    componentDidMount() {
        document.body.classList.add('signin-page');

        cookies.remove('sopotniki_volunteer', {path: '/'});
        sessionStorage.clear();

        axios.defaults.headers.common['authorization'] ? delete axios.defaults.headers.common['authorization'] : {};
        axios.defaults.headers.common['portal'] ? delete axios.defaults.headers.common['portal'] : {};
        axios.defaults.headers.common['unit_id'] ? delete axios.defaults.headers.common['unit_id'] : {};

    }

    componentWillUnmount() {
        document.body.classList.remove('signin-page');
    }

    onSubmit() {
        const _this = this;

        _this.setState({loadingInProgress: true});

        let data = {
            email: _this.state.email,
            password: _this.state.password,
            errorResponse: false
        };


        Api.login(data, function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            _this.setState({loadingInProgress: false});

            if (dataRetrieved) {

                console.log("????????");
                console.log(responseData.data);
                console.log("????????");


                let data = {
                    role: responseData.data.role,
                    userId: responseData.data.volunteer.id,
                    volunteerName: responseData.data.volunteer.first_name + ' ' + responseData.data.volunteer.last_name,
                    // user: responseData.data.volunteer,
                    token: responseData.data.token
                };

                cookies.set('sopotniki_volunteer', JSON.stringify(data), {path: '/'});

                setTimeout(function(){
                    Api.me(null, function (dataRetrieved, responseData) {
                        console.log("\n\n------");
                        console.log("\n\n ** ME **");
                        console.log(dataRetrieved);
                        console.log(responseData);
                        console.log("------\n\n");

                        if (dataRetrieved) {

                            sessionStorage.setItem('sopotniki_me', JSON.stringify(responseData.data));
                            sessionStorage.setItem('sopotniki_selected_unit_id', responseData.data.units[0].id);
                                    setTimeout(function () {
                                        _this.props.history.push('/objave');
                                    },500);







                        } else {
                            console.log("Something went wrong");
                        }
                    });
                },100)


            } else {
                _this.setState({errorResponse: true});
                console.log("Something went wrong");
            }
        });
    }

    render() {
        const _this = this;
        const spinner = <i className="text-black-50 fa fa-2x fa-circle-o-notch fa-spin fa-fw"></i>;

        return (
            <div className="signin-page">
                <div className="wrapper">
                    <div className="header">
                        <img src={Logo} className="logo img-responsive"/>
                        <div className="portalName badge badge-primary">PROSTOVOLJSKI PORTAL</div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 offset-md-3 col-sm-12 col-xs-12">


                                <Loader
                                    className="pt-2 pb-2"
                                    show={_this.state.loadingInProgress}
                                    message={spinner}
                                    contentBlur={0}
                                    backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.6)'}}>
                                    <form autoComplete="off">

                                        {_this.state.errorResponse &&
                                        <div className="row">
                                            <div className="col-sm-12 mb-2">
                                                <Alert color="danger">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <i className="pt-2 fa fa-exclamation-triangle fa-2x"></i>
                                                        </div>
                                                        <div className="col-10 text-left">
                                                            Pri prijavi je prišlo do napake. Preverite ali ste vpisali
                                                            pravilne podatke in poskusite znova. <br/><br/>
                                                            <small>V primeru nadaljnjih težav se obrnite na <a
                                                                href="mailto:info@sopotniki.org">info@sopotniki.org</a>
                                                            </small>
                                                            .
                                                        </div>
                                                    </div>
                                                </Alert>
                                            </div>
                                        </div>
                                        }


                                        <div className="mb-4 text-left"><h5>Prijava</h5></div>

                                        <div className="form-field">
                                            <input autoComplete="off" type="email" placeholder="e-mail" id="email"
                                                   onChange={_this.onChange}/>
                                        </div>
                                        <div className="form-field">
                                            <input autoComplete="off" type="password" placeholder="geslo" id="password"
                                                   onChange={_this.onChange}/>
                                        </div>
                                        <div className="form-action">
                                            <input type="submit" className="btn-shadow btn-shadow-primary"
                                                   value="Prijava"
                                                   onClick={(e) => {
                                                       e.preventDefault();
                                                       _this.onSubmit()
                                                   }}/>
                                        </div>
                                        <div className="form-bottom">
                                            <small>
                                                <Link to="/registracija">Še nisi registriran?</Link>&nbsp;&nbsp;|&nbsp;
                                                <Link
                                                    to="/pozabljeno-geslo">Si pozabil geslo?</Link>
                                            </small>
                                        </div>

                                            <div className="bottom">
                                                <p className="mt-5"><small className="">
                                                    &copy; Zavod Sopotniki. <br/>Aplikacija in vsebine so POSLOVNA SKRIVNOST Zavoda Sopotniki.
                                                </small>
                                                </p>
                                            </div>


                                    </form>
                                </Loader>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}

export default withRouter(Login)
