import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.css';
import { HashRouter } from 'react-router-dom'
import App from './components/App';
import ReactGA from "react-ga";

ReactGA.initialize('UA-139638370-1');


ReactDOM.render((
    <HashRouter>
        <App/>
    </HashRouter>
), document.getElementById('root'))


