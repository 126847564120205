import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import {Link} from "react-router-dom";
import Dropzone from 'react-dropzone'
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Cookies from "universal-cookie";
import Api_ from "../service/api";
import draftToHtml from 'draftjs-to-html';
import CKEditor from 'ckeditor4-react';
import Loader from 'react-loader-advanced';
import _ from "lodash";
import Attachment from "../components/Attachment";
import PubSub from "pubsub-js";
import config from './../config'

const cookies = new Cookies();

const Api = new Api_();
let attachmentRemovedSubscriber;
let headerImageSelectedSubscriber;

CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.11.3/full/ckeditor.js';


class NewPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postId: this.props.match.params.postId ? this.props.match.params.postId : null,
            postTitle: '',
            postBody: '',
            coverImageFiles: [],
            coverPreviews: [],
            coverImage: [],
            files: [],
            previews: [],
            attachments: [],
            renderedAttachments: [],
            publishEverywhere: false,
            loadingInProgress: false,
            selectedHeaderImage: null
        };

        this.staticTexts = {
            'newPost': "Vnos nove objave",
            'editPost': "Urejanje objave"
        };

        console.log(this.props);

        this.onChange = this.onChange.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.onEditorChange = this.onEditorChange.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderAttachments = this.renderAttachments.bind(this);
        this.attachmentRemovedSubscriber = this.attachmentRemovedSubscriber.bind(this);
        this.headerImageSelectedSubscriber = this.headerImageSelectedSubscriber.bind(this);
    }

    attachmentRemovedSubscriber(msg) {
        const _this = this;

        console.log("------- directionsSubscriber -------");
        console.log(msg);
        console.log("------- ------- \n\n");

        setTimeout(function () {
            _this.componentDidMount();
        },10);

    }

    headerImageSelectedSubscriber(msg, data) {
        const _this = this;

        console.log("------- headerImageSelectedSubscriber -------");
        console.log(data);
        console.log("------- ------- \n\n");

        _this.setState({selectedHeaderImage: data});

    }

    onChange(e) {
        this.setState({[e.target.id]: e.target.value});
    }

    toggleCheckbox(e) {
        const _this = this;

        _this.state.publishEverywhere = !_this.state.publishEverywhere;
        _this.forceUpdate();
    }

    onEditorChange = (evt) => {
        const _this = this;

        // console.log(editorState);
        // _this.setState({postBody: editorState});

        let newContent = evt.editor.getData();

        this.setState({
            postBody: newContent
        });


    }

    componentWillUnmount() {
        PubSub.unsubscribe(attachmentRemovedSubscriber);
        PubSub.unsubscribe(headerImageSelectedSubscriber);
    }


    componentDidMount() {
        const _this = this;

        attachmentRemovedSubscriber = PubSub.subscribe('ATTACHMENT_REMOVED', _this.attachmentRemovedSubscriber);
        headerImageSelectedSubscriber = PubSub.subscribe('HEADER_IMAGE_SELECTED', _this.headerImageSelectedSubscriber);

        if (_this.state.postId) {

            Api.getPost(_this.state.postId, function (dataRetrieved, responseData) {
                console.log("\n\n------");
                console.log(dataRetrieved);
                console.log(responseData);
                console.log("------\n\n");

                if (dataRetrieved) {

                    _this.setState({
                        postTitle: decodeURI(responseData.data.data.title),
                        coverImage: responseData.data.data.coverImage,
                        attachments: responseData.data.data.attachments,
                        renderedAttachments: [],
                        postBody: decodeURI(responseData.data.data.content)
                    });

                    _this.renderAttachments()

                } else {
                    console.log("Something went wrong");
                }

            });

        }


        // Api.deletePostAttachment('12')
        //     .then((response) => {
        //         console.log(response);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     })
    }

    renderAttachments() {
        const _this = this;

        if (!_this.state.attachments) return

        let attachments = [];

        _.each(_this.state.attachments, function (value, idx, arr) {
            //console.log(value);
            if ((value.mime_type === 'image/jpeg' || value.mime_type === 'image/png' || value.mime_type === 'image/gif') && !_this.state.postImg) {
                _this.setState({
                    postImg: config.apiRoot + config.actions.getFile + btoa(encodeURI('https://drive.google.com/thumbnail?authuser=0&sz=w690&id=' + value.file))
                });
            }

            attachments.push(<Attachment key={idx + '_attachment'} attachmentData={value} canDelete={true}></Attachment>)
        });

        console.log("attachments",attachments);

        _this.setState({
            renderedAttachments: attachments
        });
    }

    onDrop(files) {
        const _this = this;

        console.log(files);

        _this.setState({
            files: files,
            previews: []
        });

        files.map(f => {
            let reader = new FileReader();
            reader.readAsDataURL(f);
            reader.onload = function () {
                console.log(reader.result);

                _this.state.previews.push(reader.result);
                _this.forceUpdate();

                console.log(_this.state.previews);

            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };

        });

        console.log(files);
    }

    onCoverImageDrop(files) {
        const _this = this;

        console.log(files);

        _this.setState({
            coverImageFiles: files,
            coverImagePreviews: []
        });

        files.map(f => {
            let reader = new FileReader();
            reader.readAsDataURL(f);
            reader.onload = function () {
                console.log(reader.result);

                _this.state.coverImagePreviews.push(reader.result);
                _this.forceUpdate();

                console.log(_this.state.previews);

            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };

        });

        console.log(files);
    }

    onCancel() {
        this.setState({
            files: []
        });
    }

    onCoverImageCancel() {
        this.setState({
            coverImage: []
        });
    }

    removeCoverImageFile (index) {
        const _this = this;

        const files = _this.state.coverImageFiles.slice(0);
        const previews = _this.state.coverImagPreviews.slice(0);
        files.splice(index, 1);
        previews.splice(index, 1);

        _this.setState({
            coverImageFiles: files,
            coverImagPreviews: previews
        });
        //this.props.onChange(stateFiles);
    }

    removeFile(index) {
        const _this = this;

        const files = _this.state.files.slice(0);
        const previews = _this.state.previews.slice(0);
        files.splice(index, 1);
        previews.splice(index, 1);

        _this.setState({
            files: files,
            previews: previews
        });
        //this.props.onChange(stateFiles);
    }

    onSubmit() {
        const _this = this;

        _this.setState({loadingInProgress: true});


        let formData = new FormData();

        if(_this.state.coverImageFiles.length > 0) {
            formData.append('coverImage', _this.state.coverImageFiles[0], _this.state.coverImageFiles[0].name);
        }

        _this.state.files.forEach((file) => {
            formData.append('attachments', file, file.name);
        });

        if (_this.state.postId) {
            formData.set('id', _this.state.postId); // TODO: Set if edit
        }

        formData.set('title', encodeURI(_this.state.postTitle));
        formData.set('body', encodeURI(_this.state.postBody));
        formData.set('unit_id', sessionStorage.getItem('sopotniki_selected_unit_id'));
        if(_this.state.publishEverywhere) {
            formData.set('publishEverywhere', _this.state.publishEverywhere);
        }
        formData.set('headerImage', _this.state.selectedHeaderImage);
        formData.set('portal', 'PP');

        Api.submitPost(formData, function (dataRetrieved, responseData) {
            console.log("\n\n------");
            console.log(dataRetrieved);
            console.log(responseData);
            console.log("------\n\n");

            _this.setState({loadingInProgress: false});

            if (dataRetrieved) {

                _this.props.history.push('/objave');

            } else {
                _this.setState({errorResponse: true});
                console.log("Something went wrong");
            }
        });
    }


    render() {
        const _this = this;
        const spinner = <i className="text-black-50 fa fa-2x fa-circle-o-notch fa-spin fa-fw"></i>;

        return (

            <div>

                <Header/>


                <div className="calendar-header">
                    <section className="container">

                    </section>
                </div>


                <div className="blog-posts-list">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">


                                <div className="agency-start-project-form">
                                    <div className="container">

                                        <Loader
                                            className="pt-2 pb-2"
                                            show={_this.state.loadingInProgress}
                                            message={spinner}
                                            contentBlur={0}
                                            backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.6)'}}>

                                            <form>
                                                <h2>{_this.state.postId ? _this.staticTexts.editPost : _this.staticTexts.newPost}</h2>
                                                <h3>
                                                    Naslov objave
                                                </h3>
                                                <input type="text" id="postTitle" className="mb-4 mt-0"
                                                       value={_this.state.postTitle}
                                                       placeholder="vnesi naslov objave" onChange={_this.onChange}/>


                                                <h3>
                                                    Naslovna slika
                                                </h3>
                                                <Dropzone
                                                    id="dropzone"
                                                    accept="image/jpeg, image/png, image/gif"
                                                    multiple={false}
                                                    onDrop={this.onCoverImageDrop.bind(this)}
                                                    onFileDialogCancel={this.onCoverImageCancel.bind(this)}>

                                                    <p>povleci naslovno sliko, ki jo želiš objaviti</p><br/>

                                                    <ul>
                                                        {
                                                            _this.state.coverImageFiles.map((f, index) => {

                                                                console.log(f);

                                                                return (
                                                                    <li key={f.name} className="mb-4">


                                                                        <span>
                                                                         <img src={_this.state.coverImagePreviews[index]}
                                                                              className="img-responsive img-thumbnail"/>

                                                                            {/*<i className="fa fa-image"></i>*/}
                                                                    </span>




                                                                        <div className="row mt-1">
                                                                            <div className="col-md-9">{f.name}<br/>
                                                                                <small>{f.size} bytes</small>
                                                                            </div>
                                                                            <div className="col-md-3"><a
                                                                                className="btn btn-sm btn-danger text-white"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();

                                                                                    _this.removeCoverImageFile(index);

                                                                                }}><i
                                                                                className="fa fa-times"></i> Odstrani</a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )


                                                            })
                                                        }
                                                    </ul>
                                                </Dropzone>

                                                <h3>
                                                    Priponke (slike in dokumenti)
                                                </h3>
                                                <Dropzone
                                                    id="dropzone"
                                                    accept="image/jpeg, image/png, image/gif, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,  application/pdf"
                                                    onDrop={this.onDrop.bind(this)}
                                                    onFileDialogCancel={this.onCancel.bind(this)}>

                                                    <p>povleci priponke, ki jih želiš objaviti</p><br/>

                                                    <ul>
                                                        {
                                                            _this.state.files.map((f, index) => {

                                                                console.log(f);

                                                                return (
                                                                    <li key={f.name} className="mb-4">


                                                                        {(f.type === 'image/jpeg' || f.type === 'image/png' || f.type === 'image/gif') &&
                                                                        <span>
                                                                         <img src={_this.state.previews[index]}
                                                                              className="img-responsive img-thumbnail"/>

                                                                            {/*<i className="fa fa-image"></i>*/}
                                                                    </span>
                                                                        }

                                                                        {(f.type !== 'image/jpeg' && f.type !== 'image/png' && f.type !== 'image/gif') &&
                                                                        <i className="fa fa-2x fa-file"></i>
                                                                        }

                                                                        <div className="row mt-1">
                                                                            <div className="col-md-9">{f.name}<br/>
                                                                                <small>{f.size} bytes</small>
                                                                            </div>
                                                                            <div className="col-md-3"><a
                                                                                className="btn btn-sm btn-danger text-white"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();

                                                                                    _this.removeFile(index);

                                                                                }}><i
                                                                                className="fa fa-times"></i> Odstrani</a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )


                                                            })
                                                        }
                                                    </ul>
                                                </Dropzone>

                                                {_this.state.renderedAttachments.length > 0 &&
                                                <div className="mt-2">
                                                    <small>Že dodane priponke:</small>
                                                    <div className="row">
                                                        {_this.state.renderedAttachments}
                                                    </div>
                                                </div>
                                                }

                                                <div className="mb-4 mt-0">&nbsp;</div>


                                                <h3>
                                                    Vsebina objave
                                                </h3>


                                                <CKEditor
                                                    data={_this.state.postBody}
                                                    onChange={evt => _this.onEditorChange(evt)}
                                                    config={{
                                                       lang: 'si',
                                                        toolbar: [
                                                            ['Bold', 'Italic', 'Underline', 'StrikeThrough', '-', 'Undo', 'Redo', '-', 'Cut', 'Copy', 'Paste'],
                                                            '/',
                                                            ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                                                            ['Link', 'Unlink'],
                                                            ['Smiley', 'Iframe'],
                                                            ['TextColor', 'Source']
                                                        ]
                                                    }}/>


                                                <div className="mb-4 mt-0">&nbsp;</div>

                                                {cookies.get('sopotniki_volunteer').role === 1 &&
                                                <div>
                                                    <h3>
                                                        Objavi na prostovoljskih portalih za vse enote
                                                    </h3>


                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <label className="ml-3 mr-3">
                                                                <Input type="checkbox" id="publishEverywhere"
                                                                       name="publishEverywhere"
                                                                       checked={this.state.publishEverywhere}
                                                                       onChange={(e) => {
                                                                           this.toggleCheckbox(e);
                                                                       }}
                                                                />
                                                                ta objava naj bo vidna na vseh prostovoljsih
                                                                portalih <small>(možnost je aktivna samo za
                                                                administratorje)</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                }

                                                <div className="row">
                                                    <div className="col-md-12 mt-3">
                                                        <small><Link className="pt-3" to='/objave'>Prekliči</Link>
                                                        </small>
                                                        <a className="btn-pill btn-pill-success btn-pill-lg text-white customFadeInUp pull-right"
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               _this.onSubmit();
                                                           }}><i
                                                            className="fa fa-check"></i> Objavi</a>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </form>
                                        </Loader>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                <Footer/>


            </div>
        )
    }
}


export default NewPost
