import React from 'react'
import Cookies from "universal-cookie";
import {withSwalInstance} from 'sweetalert2-react';
import swal from 'sweetalert2';
import Api_ from "../service/api";
import ReactGA from 'react-ga';
import $ from "jquery";
import visible from "jquery-visible"
import moment from "moment";

const cookies = new Cookies();
const Api = new Api_();
const SweetAlert = withSwalInstance(swal);

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class ReservationItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reservationData: this.props.reservationData ? this.props.reservationData : {},
            index: this.props.index ? this.props.index : 0
        };

        console.log(this.props);

    }


    componentDidMount() {
        const _this = this;
    }

    componentWillUnmount() {
    }

    render() {
        const _this = this;
        const spinner = <i className="text-black-50 fa fa-2x fa-circle-o-notch fa-spin fa-fw"></i>;

        return (

            <div className="row">

                <div className="col-md-12">

                    <div className="card mt-1 mb-1">

                        <div className="card-body">

                            <h2 className="badge badge-secondary">prevoz #{_this.state.index}</h2>
                            <div className="clearfix mb-1"></div>

                            <table className="table text-left">
                                <thead>
                                    <tr>
                                        <th style={{width: '20%'}}>ura</th>
                                        <th>rezervacija</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        {moment(_this.state.reservationData.departure_time).format('HH:mm')}<br/>
                                        <i>do</i><br/>
                                        {moment(_this.state.reservationData.arrival_time).format('HH:mm')}
                                    </td>

                                    <td>
                                         <b>{_this.state.reservationData.customer.first_name} {_this.state.reservationData.customer.last_name}</b><br/>
                                         <em>{_this.state.reservationData.customer.notes}</em>
                                        <div className="mt-2">
                                            <small className="label">mesto odhoda: </small>
                                            <a target={"_blank"} href={"https://www.google.com/maps/search/?api=1&query=" + _this.state.reservationData.departure.quick_search.split(' ').join('+')}>{_this.state.reservationData.departure.quick_search} {_this.state.reservationData.departure.address_name ? '(' + _this.state.reservationData.departure.address_name + ')' : ''}</a>
                                        </div>
                                        <div>
                                            <small className="label">destinacija: </small>
                                            <a target={"_blank"} href={"https://www.google.com/maps/search/?api=1&query=" + _this.state.reservationData.destination.quick_search.split(' ').join('+')}>{_this.state.reservationData.destination.quick_search} {_this.state.reservationData.destination.address_name ? '(' + _this.state.reservationData.destination.address_name + ')' : ''}</a>
                                        </div>
                                        <div>
                                            <small className="label">mesto povratka: </small>
                                            <a target={"_blank"} href={"https://www.google.com/maps/search/?api=1&query=" + _this.state.reservationData.arrival.quick_search.split(' ').join('+')}>{_this.state.reservationData.arrival.quick_search} {_this.state.reservationData.arrival.address_name ? '(' + _this.state.reservationData.arrival.address_name + ')' : ''}</a>
                                        </div>

                                        <div className="card bg-light mt-2">
                                            <div className="card-body">
                                                {_this.state.reservationData.reservations_notes}
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            {/*RESERVATION*/}

                            {/*<div className="m-5">{JSON.stringify(_this.state.reservationData)}</div>*/}
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}


export default ReservationItem
