import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import bgImg from '../images/unsplash/aranxa-esteve-130749.jpg'
import avatar from '../images/uifaces/10.jpg'
import PostItem from '../components/PostItem'
import {Link, withRouter} from "react-router-dom";
import Api_ from "../service/api";
import Cookies from "universal-cookie";
import Loader from 'react-loader-advanced';
import PubSub from "pubsub-js";
import StatisticsOverlay from "../components/StatisticsOverlay";
import ReactGA from 'react-ga';
import {FacebookProvider, Page} from 'react-facebook';
import _ from "lodash";
import $ from 'jquery';

const Api = new Api_();
const cookies = new Cookies();

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class FBposts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingInProgress: false,
        };
    }

    componentDidMount() {
        const _this = this;

        ReactGA.pageview("/fb-objave");

        // _this.setState({
        //     loadingInProgress: true
        // });

    }

    componentWillUnmount() {

    }

    render() {
        const _this = this;
        const spinner = <i className="text-black-50 fa fa-2x fa-circle-o-notch fa-spin fa-fw"></i>;

        return (

            <div>

                <Header/>


                <div className="instructions-header">
                    <section className="container">

                    </section>
                </div>


                <div className="calendar-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center m-auto">

                                <Loader
                                    className="pt-2 pb-2"
                                    show={_this.state.loadingInProgress}
                                    message={spinner}
                                    contentBlur={0}
                                    backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.6)'}}>

                                    <div className="mt-3">
                                        <FacebookProvider appId="1778488579117650">
                                            <Page href="https://www.facebook.com/Sopotniki/" tabs="timeline" width="500"
                                                  height="1000" style={{margin: "0px auto"}}/>
                                        </FacebookProvider>
                                    </div>

                                </Loader>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer/>


            </div>
        )
    }
}


export default withRouter(FBposts)
