import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import bgImg from '../images/unsplash/aranxa-esteve-130749.jpg'
import avatar from '../images/uifaces/10.jpg'
import PostItem from '../components/PostItem'
import Loader from 'react-loader-advanced';
import ReactGA from "react-ga";

class Instructions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingInProgress: false,
            iframeUrl: 'https://prostovoljci.sopotniki.org/navodila?document=' + this.getUrlPram('document', this.props.location.search)
        };

        this.onIframeLoaded = this.onIframeLoaded.bind(this);
    }


    componentWillMount() {
        const _this = this;


    }

    componentWillReceiveProps(nextProps){
        console.log(nextProps);

        this.setState({
            loadingInProgress: true,
            iframeUrl: 'https://prostovoljci.sopotniki.org/navodila?document=' + this.getUrlPram('document', nextProps.location.search)
        });




    }

    componentDidMount() {
        const _this = this;

        ReactGA.pageview('/navodila?document=' + this.getUrlPram('document', window.location.href));

        _this.setState({
            loadingInProgress: true
        });
    }

    componentWillUnmount() {

    }


    getUrlPram(name, url) {
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        let regexS = "[\\?&]" + name + "=([^&#]*)";
        let regex = new RegExp(regexS);
        let results = regex.exec(url);
        return results == null ? null : results[1];
    }

    onIframeLoaded() {
        const _this = this;

        _this.setState({
            loadingInProgress: false
        });
    }

    render() {
        const _this = this;
        const spinner = <i className="text-black-50 fa fa-2x fa-circle-o-notch fa-spin fa-fw"></i>;

        return (

            <div>

                <Header/>


                <div className="instructions-header">
                    <section className="container">

                    </section>
                </div>


                <div className="calendar-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <Loader
                                    className="pt-2 pb-2"
                                    show={_this.state.loadingInProgress}
                                    message={spinner}
                                    contentBlur={0}
                                    backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.6)'}}>

                                    <iframe src={_this.state.iframeUrl} frameBorder={0}
                                            style={{width: '100%', height: 1000}} allowFullScreen={true}
                                            onLoad={_this.onIframeLoaded}></iframe>

                                </Loader>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer/>


            </div>
        )
    }
}

export default Instructions
