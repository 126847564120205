import React from 'react'
import {Link} from 'react-router-dom'


const Footer = () => (

  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="title">
            Hitre povezave
          </div>
          <ul className="menu">
            <li>
              <Link to="/koledar">E-koledar prostovoljstva</Link>
            </li>
            <li>
              <a href="https://www.sopotniki.org" target='_blank'>Spletno mesto Zavoda Sopotniki</a>
            </li>
          </ul>
        </div>
        <div className="col-md-4">
          <div className="title">
            Ostanimo povezani
          </div>
          <ul className="menu">
            <li>
              <a href="https://www.facebook.com/Sopotniki/" target="_blank">
                <i className="fa fa-facebook"></i>
                Facebook
              </a>
            </li>
          </ul>
        </div>
        {/*<div className="col-md-4 newsletter">*/}
        {/*<div className="title">*/}
        {/*Get awesome updates*/}
        {/*</div>*/}
        {/*<p>*/}
        {/*Enter your email address for news and product launches in the Awesome Space.*/}
        {/*</p>*/}

        {/*<form className="newsletter-form" autoComplete="off">*/}
        {/*<input type="email" id="mc-email" className="form-control"*/}
        {/*placeholder="Email address" required/>*/}

        {/*<button type="submit">*/}
        {/*<i className="fa fa-chevron-right"></i>*/}
        {/*</button>*/}
        {/*<label htmlFor="mc-email" className="text-white newsletter-feedback mt-3"></label>*/}
        {/*</form>*/}
        {/*</div>*/}
      </div>
      <div className="bottom">
        <p className="pt-3"><small className="text-white">
          &copy; Zavod Sopotniki. Aplikacija in njene vsebine so POSLOVNA SKRIVNOST Zavoda Sopotniki.
        </small>
        </p>
      </div>
    </div>
  </footer>

)

export default Footer
