import React from 'react'
import Logo from '../images/logotip.svg'
import Api_ from '../service/api'
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
    withRouter
} from 'react-router-dom'
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Loader from 'react-loader-advanced';
import Cookies from 'universal-cookie';
import config_ from "../config";

const Api = new Api_();
const cookies = new Cookies();
const config = config_;

class Error extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loadingInProgress: false,
            errorResponse: false
        }

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.id]: e.target.value});
    }


    componentDidMount() {
        document.body.classList.add('signin-page');

        cookies.remove('sopotniki_volunteer', {path: '/'});
        sessionStorage.clear();
    }

    componentWillUnmount() {
        document.body.classList.remove('signin-page');
    }

    render() {
        const _this = this;
        const spinner = <i className="text-black-50 fa fa-2x fa-circle-o-notch fa-spin fa-fw"></i>;

        return (
            <div className="">
                <div className="wrapper">
                    <div className="header">
                        <img src={Logo} className="logo img-responsive"/>
                        <div className="portalName badge badge-info">PROSTOVOLJSKI PORTAL</div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 offset-md-3 col-sm-12 col-xs-12">


                                <form autoComplete="off">

                                    <div className="row">
                                        <div className="col-sm-12 mb-2">
                                            <Alert color="info">
                                                <div className="row">
                                                    <div className="col-2">
                                                        <h1>😢</h1>
                                                    </div>
                                                    <div className="col-10 text-left">
                                                        <h2>Prišlo je do napake.</h2>

                                                        <small>V primeru nadaljnjih težav se obrnite na <a
                                                            href="mailto:info@sopotniki.org">info@sopotniki.org</a>
                                                        </small>
                                                        .

                                                        <div className="mt-5 mb-3">
                                                            <Link
                                                                className="btn-pill btn-pill-info"
                                                                to="/objave">Domov</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Alert>
                                        </div>
                                    </div>


                                </form>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}

export default withRouter(Error)
